import React from 'react'
import data from '../../constants/data'

const Partener = () => {
    return (
        <>
            <section className="w-full">
                <div className="bg-slate-50">
                    <div className="container">
                        <div className="flex py-[50px] justify-center  items-center flex-col md:flex-row flex-wrap ">
                            <div className="grid grid-cols-4 lg:grid-cols-6 gap-1 place-items-center ">
                                { 
                                    data.partener.map((name, idx) => (
                                        <img id={idx} className="rounded duration-500" src={name.logo} alt={name.alt} />
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Partener