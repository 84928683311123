import React from 'react'

import { Link } from 'react-router-dom';
import { Carousel } from "flowbite-react";
// import Bannermg1 from "../../../assets/img/home/banner/3.png";
// import Bannermg2 from "../../../assets/img/home/banner/1.png";

const BannerContent = () => {
    return (
        <>
            <section className="overflow-hidden bg-green-50">
                <div className="h-screen w-full mx-auto ">
                    <Carousel 
                    pauseOnHover
                    slideInterval={50000}
                    className="h-full  rounded-[90px] m-[0] p-[0] w-full">
                    <div className="flex relative w-full h-full p-[0] m-[0] items-center justify-center  ">
                        {/* <img src={Bannermg1} alt="..." className="h-full absolute  w-full object-cover"/> */}
                        <video loop autoPlay muted src="/banner/1.mp4" className="h-full  absolute  w-full object-cover"/>
                        <div className="absolute z-[1] object-fill h-full bg-opacity-60 w-full bg-green-950"></div>
                            <div className=" text-center z-[999] ">
                            <h1 className="font-extrabold mx-[20px] text-[40px] sm:text-[70px] md:text-[80px] lg:text-[100px]">
                                <span className="text-primary-tx" >PHARMAsourcing</span>
                            </h1>
                            <div className="text-1xl my-[10px] font-normal text-slate-100 -tracking-tighter">
                                Your ophtamologist partner
                            </div>
                            <div className="flex mt-[3rem] justify-center ">
                                <Link activeClass="active" className=" pointer block border-[2px] border-solid border-slate-100 px-3 py-2 md:px-5 md:py-3 rounded-[0.5rem]  hover:bg-slate-100 hover:text-green-800 text-slate-100 duration-500 "  to="/about">
                                About our world
                                </Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="flex relative h-full  items-center justify-center  ">
                    {/* <img src={Bannermg2} alt="..." className="h-full absolute  w-full object-cover"/> */}
                    <video loop autoPlay muted src="/banner/2.mp4" className="h-full z-[1] absolute  w-full object-cover"/>
                    <div className="absolute z-[1] h-full bg-opacity-60 w-full bg-green-950"></div>
                        <div className=" text-center z-[999] ">
                        <h1 className="font-extrabold mx-[20px] text-[40px] sm:text-[70px] md:text-[80px] lg:text-[100px]">
                            <span className="text-primary-tx" >PHARMAsourcing</span>
                        </h1>
                        <div className="text-1xl my-[10px] font-normal text-slate-100 -tracking-tighter">
                            Your ophtamologist partner
                        </div>
                        <div className="flex mt-[3rem] justify-center ">
                            <Link activeClass="active" className=" pointer block border-[2px] border-solid border-slate-100 px-3 py-2 md:px-5 md:py-3 rounded-[0.5rem]  hover:bg-slate-100 hover:text-green-800 text-slate-100 duration-500 "  to="about">
                            About our world
                            </Link>
                        </div>
                    </div>
                    </div>
                </Carousel>
                </div>
            </section>
        </>
    )
}

export default BannerContent