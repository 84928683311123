import React from 'react'
import { Link } from 'react-router-dom';
import PassionImg1 from "../../../assets/img/home/passion/5.jpg";
import PassionImg2 from "../../../assets/img/home/passion/8.jpg";
import PassionImg3 from "../../../assets/img/home/passion/9.jpg";
import PassionImg4 from "../../../assets/img/home/passion/machine.jpg";

const Passion = () => {
    return (
        <>
            <div className="bg-green-100">
                <div className="container">
                    <div className="pt-[50px] pb-[20px]">
                        <h2 className="text-[40px] font-extrabold">what we help you</h2>
                    </div>
                    <div className="flex pb-[50px] justify-around items-center w-full flex-col md:flex-row flex-wrap ">
                        <div className="relative  w-screen md:w-[100%]  h-[80vh] overflow-hidden  my-[10px] border border-gray-20 shadow">
                            <Link className="z-1 w-full h-full">
                                <img className="rounde-t-lg w-full object-cover h-full" src={PassionImg1} alt="" />
                            </Link>
                            <div className="absolute top-[0] w-full h-full bg-black opacity-40"></div>
                            <div className="p-2 absolute bottom-[0] flex flex-col left-[0]">
                                <p className="font-semibold text-slate-100 text-2xl tracking-tight ">Diagnose better and more completely</p>
                                <p className="text-slate-100">It involves a comprehensive assessment that may include <br/> 
                                    a detailed medical history, physical examination, 
                                    and various diagnostic texts.</p>
                                <Link to="/about" className="py-2 px-2 mt-[20px] rounded border-solid border border-slate-100 max-w-[150px] inline text-center duration-500 text-slate-100 hover:bg-slate-100 hover:text-primary-bg ">
                                    More about
                                </Link>
                            </div>
                        </div>

                        <div className="relative w-screen  md:w-[31%]  h-[80vh] overflow-hidden  my-[10px] border border-gray-20 shadow">
                            <Link className="z-1 w-full  h-full">
                                <img className="rounde-t-lg w-full object-cover  h-full" src={PassionImg2} alt="" />
                            </Link>
                            <div className="absolute top-[0] w-full h-full bg-black opacity-40"></div>
                            <div className="p-3 absolute bottom-[0] flex flex-col left-[0]">
                                <p className="font-semibold text-slate-100 text-1xl tracking-tight ">Treat your patients with <br/>optimal outcomes</p>
                                <p className="font-normal text-slate-100">It's about maximizing thepositive effects of
                                    healthcare inteventions while minimizing any 
                                    potential negative side effects or complication.</p>
                            </div>
                        </div>

                        <div className="relative w-screen  md:w-[31%]  h-[80vh] overflow-hidden  my-[10px] border border-gray-20 shadow">
                            <Link className="z-1 w-full  h-full">
                                <img className="rounde-t-lg w-full object-center object-contain bg-slate-300 md:object-cover h-full" src={PassionImg3} alt="" />
                            </Link>
                            <div className="absolute top-[0] w-full h-full bg-black opacity-40"></div>
                            <div className="p-3 absolute bottom-[0] flex flex-col left-[0]">
                                <p className="font-semibold text-slate-100 text-1xl tracking-tight ">Work with greater efficiency <br/>and flexibility</p>
                                <p className="font-normal text-slate-100">Doing thing in an optimal way,
                                    such as using resources wisely and streamlining processes the best result in the shortest amount of time.
                                </p>
                            </div>
                        </div>

                        <div className="relative w-screen  md:w-[31%]  h-[80vh] overflow-hidden  my-[10px] border border-gray-20 shadow">
                            <Link className="z-1 w-full  h-full">
                                <img className="rounde-t-lg w-full object-cover h-full" src={PassionImg4} alt="" />
                            </Link>
                            <div className="absolute top-[0] w-full h-full bg-black opacity-40"></div>
                            <div className="p-3 absolute bottom-[0] flex flex-col left-[0]">
                                <p className="font-semibold text-slate-100 text-1xl tracking-tight ">Grow your practice and <br/> service offerings</p>
                                <p className="font-normal text-slate-100"> We are proud to tell you that a Pharma Sourcing, 
                                you are in front of a good guide for the improvement of your vision</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  
        </>
    )
}

export default Passion