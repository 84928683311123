import { BriefcaseIcon, CircleStackIcon, CpuChipIcon } from "@heroicons/react/24/outline"

const ProductItems = {
    products:[
        {
            id: "surgical-instruments",
            libelle: "Surgical instruments",
            icon: BriefcaseIcon,
            imgBanner: "/img/products/banner/11.jpg",
            isCard: false,
            categories: [
                {
                    id:"3c27aed6-7d0e-4a6f-9d49-4be4886146c1", 
                    libelle:"Speculum",
                    contents:[
                        {
                            name: "Baraquer wire speculum size 5 mm blade extra small",
                            model: "AAT 1004",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/1.png",
                        },
                        {
                            name: "Baraquer wire speculum size 8 mm blade small",
                            model: "AAT 1005",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/2.png",
                        },
                        {
                            name: "Baraquer wire speculum size 12 mm blade",
                            model: "AAT 1006",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/3.png",
                        },
                        {
                            name: "Baraquer wire speculum size 15 mm blade",
                            model: "AA 1008",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/4.png",
                        },
                        {
                            name: "Baraquer wire speculum with solid Blades size 8 mm blade",
                            model: "AA 1015",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/5.png",
                        },
                        {
                            name: "Baraquer wire speculum with solid Blades size 13 mm blade large",
                            model: "AAT 1016",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/6.png",
                        },
                        {
                            name: "... wire speculum size 4 mm blade",
                            model: "AA 1035",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/7.png",
                        },
                        {
                            name: "kratz-barraquer wire speculum with open blades for phaco surgery size 10 mm blade small",
                            model: "AAT 1020",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/8.png",
                        },
                        {
                            name: "kratz-barraquer wire speculum with open blades for phaco surgery size 15 mm blade large",
                            model: "AAT 1025",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/9.png",
                        },
                        {
                            name: "barraquer wire speculum size 15 mm blade large",
                            model: "AAT 1004",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/10.png",
                        },
                        {
                            name: "kalpesh wire speculum heavy wire, large size 14 mm blade",
                            model: "AA 1026",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/11.png",
                        },
                        {
                            name: "wire speculum with finger grips size 4 mm closed blades",
                            model: "AA 1018",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/12.png",
                        },
                        {
                            name: "barraquer wire speculum size 15 mm blade heavy wire, large",
                            model: "AA 1010",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/13.png",
                        },
                        {
                            name: "wire speculum large size 5.5 mm blade",
                            model: "AA 1037",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/14.png",
                        },
                        {
                            name: "aspirating speculum size 15 mm blade",
                            model: "AA 1012",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/15.png",
                        },
                        {
                            name: "kratz-barraquer wire speculum size 6 mm blade extra small",
                            model: "AA 1019",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/16.png",
                        },
                        {
                            name: "sauer lid speculum for infants",
                            model: "AA 1065",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/17.png",
                        },
                        {
                            name: "sauer lid speculum for premature infants",
                            model: "AA 1070",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/18.png",
                        },
                        {
                            name: "fishkind - castroviejo lid speculum with open blades for phaco surgery",
                            model: "AAT 1144",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/19.png",
                        },
                        {
                            name: "Murdock Eye speculum fenestrated with locking screw Small #1",
                            model: "AA 1080",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/20.png",
                        },
                        {
                            name: "Castroviejo Eye speculum open blades Small #1",
                            model: "AA 1140",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/21.png",
                        },
                        {
                            name: "Lieberman - Tennant Eye speculum open blades for temporal placement Nasal",
                            model: "AAT 1183",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/22.png",
                        },
                        {
                            name: "Castroviejo Eye speculum open blades Medium #2",
                            model: "AA 1142",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/23.png",
                        },
                        {
                            name: "Williams Eye speculum open blades Large",
                            model: "AA 1105",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/24.png",
                        },
                        {
                            name: "Murdock Eye speculum fenestrated with locking screw Small #1",
                            model: "AA 1080",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/25.png",
                        },
                        {
                            name: "Eye speculum open blades",
                            model: "AA 1095 Large",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/26.png",
                        },
                        {
                            name: "Cook Eye speculum solid with locking screw Medium #2",
                            model: "AA 1076",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/27.png",
                        },
                        {
                            name: "Eye solid blades for temporal placement.Nasal",
                            model: "AA 1186",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/28.png",
                        },
                        {
                            name: "Murdock Eye speculum fenestrated with locking screw Large#3",
                            model: "AA 1082",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/29.png",
                        },
                        {
                            name: "Lieberman - Tennant Eye speculum open blades for temporal placement Nasal",
                            model: "AAT 1183",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/30.png",
                        },
                        {
                            name: "Agarwal No anesthesia self retaining eye speculum solid blades",
                            model: "AAT 1125",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/31.png",
                        },
                        {
                            name: "Clark Eye speculum open blades Small",
                            model: "AAT 1090",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/32.png",
                        },
                        {
                            name: "Lieberman - Tennant Eye speculum open bladesfor temporal placement Nasal",
                            model: "AAT 1187",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/33.png",
                        },
                        {
                            name: "Lancaster Eye speculum solid blades",
                            model: "AA 1120",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/34.png",
                        },
                        {
                            name: "Irrigating speculum with six ports for temporal approach",
                            model: "AA 1086 Temporal",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/35.png",
                        },
                        {
                            name: "LASIK speculum",
                            model: "AAT 1195",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/36.png",
                        },
                        {
                            name: "Reversible speculumnasal & temporal fit",
                            model: "AAT 1196",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/speculum/37.png",
                        },
                    ]
                },
                {
                    id:"b2415dc6-39ad-48af-9504-844d985a5402", 
                    libelle:"Retractors",
                    contents:[
                        {
                            name: "mueller lacrimal sac retractor spring action with screw lock 3 x 3 sharp prongs",
                            model: "AA 1185",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/10.png",
                        },
                        {
                            name: "stevenson lacrimal sac retractor 3 x 3 blunt prongs self-retaining adjustable spread",
                            model: "AA 1200",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/11.png",
                        },
                        {
                            name: "fascia needle size 140 mm, sharp edge",
                            model: "AA 1215",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/12.png",
                        },
                        {
                            name: " desmarres lid retractor solid blades size 0,11 mm width",
                            model: "AAT 1260",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/1.png",
                        },
                        {
                            name: "desmarres lid retractor solid blades size 1, 12 mm width",
                            model: "AAT 1261",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/2.png",
                        },
                        {
                            name: "desmarres lid retractor solid blades size 2, 13 mm width",
                            model: "AAT 1262",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/3.png",
                        },
                        {
                            name: "desmarres lid retractor solid blades size 3, 14 mm width",
                            model: "AAT 1263",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/4.png",
                        },
                        {
                            name: "schepens forked orbital retractor",
                            model: "AAT 1270",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/5.png",
                        },
                        {
                            name: "helveston tissue retractor thin curved blade size 7 mm wide ",
                            model: "AA 1275",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/6.png",
                        },
                        {
                            name: "helveston tissue retractor thin curved blade size 9 mm wide",
                            model: "AA 1276",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/7.png",
                        },
                        {
                            name: "dastoor iris retractor 3.5 mm wide straight",
                            model: "AA 1250",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/8.png",
                        },
                        {
                            name: " desmarres lid retractor solid blades size 4, 15 mm width",
                            model: "AA 1264",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/retractors/9.png",
                        },
                        
                    ]
                },
                {
                    id:"aa63b8e9-0314-4caa-8c17-d13c8eb6caf0", 
                    libelle:"Scleral Depressors",
                    contents:[
                        {
                            name: "Schocket Scleral Depressor Double-End With Pocket Clip ",
                            model: ":AAT 1310",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/scleralDepressors/1.png",
                        },
                        {
                            name: "Schepens Scleral Depressor With Thimble Medium #10",
                            model: "AA 1305",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/scleralDepressors/2.png",
                        },
                        {
                            name: "Depressor smoothly adheres flap to stromal bed in pupillary area",
                            model: "AAT  1308",
                            modelImg: "/products/surgical-instruments/scleralDepressors/M1.png",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/scleralDepressors/3.png",
                        },
                        {
                            name: "Scleral depressor",
                            model: "AA  1311",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/scleralDepressors/4.png",
                        },
                        {
                            name: "Scleral depressor",
                            model: "AA  1312",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/scleralDepressors/5.png",
                        },
                        {
                            name: "Pediatric scleral depressor",
                            model: "AA  1312",
                            modelImg: "/products/surgical-instruments/scleralDepressors/M2.png",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/scleralDepressors/6.png",
                        },
                        {
                            name: "Ziegler Knife Needle",
                            model: "AA  1325",
                            modelImg: "/products/surgical-instruments/scleralDepressors/M3.png",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/scleralDepressors/7.png",
                        }
                    ]
                },
                // {
                //     id:"ebc52f9f-19b7-4137-b865-eb69252eb860", 
                //     libelle:"Knives",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/knives/1.png",
                //         },
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/knives/2.png",
                //         },
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/knives/3.png",
                //         },
                        
                //     ]
                // },
                {
                    id:"08ece44f-b07a-449b-856f-88a9de62b8f4", 
                    libelle:"Dissectors",
                    contents:[
                        {
                            name: "Paton Corneal Dissector Hockey Stick Knife ",
                            model: "AAT 1390",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/dissectors/3.png",
                        },
                        {
                            name: "Dastoor Lacrimal Sac Dissector,Blunt",
                            model: "AA 1395",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/dissectors/2.png",
                        },
                        {
                            name: "Lang's Lacrimal Sac Dissector, Sharp",
                            model: "AA 1397",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/dissectors/1.png",
                        },
                        {
                            name: "Dia 0.25mm,11mm Tip Overall Length 125mm",
                            model: "AA 1450",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/surgical-instruments/dissectors/4.png",
                        }
                    ]
                },
                // {
                //     id:"69228341-0264-473b-980c-c909a634f4e2", 
                //     libelle:"Spuds",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"ffdb6aef-7b8f-47d0-885b-19fe1848a7c1", 
                //     libelle:"Lens Manipulators",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"f35afd86-6e02-4852-baaa-f30e8cbe5e22", 
                //     libelle:"No Hole Lens Manipulators",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"31e073c4-12e8-445a-a21f-651617a96afe", 
                //     libelle:"Nucleus Rotators",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"0a74a42a-527b-4437-a2c8-6ddd971c201e", 
                //     libelle:"Spatulas",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"9b83cb87-1399-4acf-85fe-da5eb7e93cb6", 
                //     libelle:"Fixation Rings",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"b490d0d9-186a-4bb6-8f7e-69871588d9ba", 
                //     libelle:"Muscle Hooks",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"c636b372-4d74-4fa7-adfc-bf517b2661a2", 
                //     libelle:"CTR Injector",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"e5dc39ca-af52-45e9-a87e-3a2a59231b5e", 
                //     libelle:"Lens Expressors",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"8a52c8dd-d732-472b-8617-8dd9006938a9", 
                //     libelle:"Curette & Spoons",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"025b2dd2-a8ae-46be-a791-96969380e830", 
                //     libelle:"Polishers & Lens Loop",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"8019dfaa-d5d5-446b-b1af-fd487133e7e5", 
                //     libelle:"Cautery, Nucleus Bisector & Trisector",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"18221205-f16d-4f30-861a-da9433a6f12b", 
                //     libelle:"Phaco Choppers",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"12221205-f16d-4f30-861a-da2433a6f43b", 
                //     libelle:"Calipers",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"45ea157a-dd01-41cf-a0f0-6ddcb55abd57", 
                //     libelle:"Markers & Dialators",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"6484f69e-9ad4-4240-bac6-9f5eb9d6c11c", 
                //     libelle:"Lasek & Dsek",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"ffa4ab74-2071-43dd-94f0-27994dc99e0a", 
                //     libelle:"Toric Markers",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"e99da393-dc55-4a9f-810f-1d3f47ba9a92", 
                //     libelle:"Forceps",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"cb9e66d1-eee9-4f96-bfa5-ff50127dba07", 
                //     libelle:"Scissors",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"29f95fcb-bd93-4bcc-83b5-f410cc132e92", 
                //     libelle:"Needle Holder",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"e9ddd629-b2f3-4770-9de6-3310c6280f2e", 
                //     libelle:"Blade Breaker",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"6dbe95d6-5ca6-4184-8a72-f1b7257fd492", 
                //     libelle:"Blades & Handles",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"16a9f88e-8f47-4e17-85d9-400bc695ab92", 
                //     libelle:"Punches",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"7cb9c756-a1ba-419c-b9df-bf54d590f6fa", 
                //     libelle:"Dcr Instruments",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"5bed20a5-c97e-4ca8-8e54-dcf80f3a5234", 
                //     libelle:"Lacrimal Probes",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"2f8283d8-17e5-4c07-8567-2ffc448340fb", 
                //     libelle:"Dilators",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"e3b8281f-1eae-4f63-a546-3e9c97238f1f", 
                //     libelle:"Cannula",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"1458443a-08ff-48ae-a495-59c4182c6f79", 
                //     libelle:"Sterilization Boxes",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"437451b6-d17f-481d-9e01-d1953e30a803", 
                //     libelle:"Phaco Tips",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"4a9318a9-403a-44e2-9579-c10941a3abe2", 
                //     libelle:"Bipolar Forceps & Eraser",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },
                // {
                //     id:"688ed8a4-ccb5-4999-9667-dfdf26eaf8e8", 
                //     libelle:"Bimanual I/a Han",
                //     contents:[
                //         {
                //             name: " ",
                //             model: "AA",
                //             modelImg: "",
                //             descriptions: "",
                //             otherDescription: [],
                //             compositionTitle: "",
                //             compositionContents: [],
                //             childs:[],
                //             features: [],                      
                //             img: "/products/surgical-instruments/",
                //         }
                //     ]
                // },

            ]
        },
        {
            id: "pharmaceuticals",
            libelle: "Pharmaceuticals",
            icon: CircleStackIcon,
            imgBanner: "/img/products/banner/6.jpg",
            isCard: true,
            categories: [
                {
                    id:"11813141-b2bb-4fb9-8eb8-6f02ca2f4357", 
                    libelle: "Anti - Allergic",
                    contents: [
                        {
                            name: "ANC",
                            model: "Pack: 10ml Pack",
                            modelImg: "",
                            descriptions: "ANC Eye Drops actas an anti allergic, astringent & decongestant. Naphazoline acts as a vasoconstrictor to narrow swollen blood vessels to reduce the redness. Hydrxtpropylmethyl cellulose acts as lubricant to give relief from dry irritated eyes. ‍Chlorpheniramine maleate acts as antiallergic gives relief from itching, watering of eyes due to allergy. Zinc sulphate acts as an astringent that helps clear mucus from the outer surface of the eye often associated with exposure to pollen, dust and smoke.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Cromolyn Sodium USP...........20 mg"},
                                {item: "Naphazoline Hcl USP .............. 0.56 mg"},
                                {item: "Chlorpheniramine Maleate IP............0.1mg"},
                                {item: "Zinc Sulphate IP..................1.2 mg"},
                                {item: "Hypromellose IP ................2 mg"},
                                {item: "Benzalkonium Chloride Solution IP..........0.0001ml (as preservative)"},
                                {item: "Sterile Aqueous vehicle............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-allergic/1.png",
                        },
                        {
                            name: "K-FEN 0.5",
                            model: "(Olopatadine Ophthalmic Solution IP)",
                            modelImg: "",
                            descriptions: "K-FEN 0.5 (Ketotifen Ophthalmic Solution)is a sterile ophthalmic solution containing ketotifen for topical administration to the eyes. Ketotifen fumarate is a finely crystalline powder with an empirical formula of C23H23NO5S and a molecular weight of 425.50.‍",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Ketotifen Fumarate IP"},
                                {item: " Eq. to Ketotifen                       0.5mg"},
                                {item: "Benzalkonium Chloride"},
                                {item: "Solution IP                              0.05mg (As preservative)"},
                                {item: "Sterile Aqueous Vehicle        q .s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-allergic/2.webp",
                        },
                        {
                            name: "ALLER-NIL",
                            model: "Pack: 5ml Pack",
                            modelImg: "",
                            descriptions: "Allernil- Eye Drops (Cromolyn Sodium Ophthalmic Solution) is an anti-inflammatory medication used to treat allergysymptoms that affect the eyes, such as itching, burning, watering, swelling, redness, or sensitivity to light. Opticrom is available in generic form. Common side effects of Opticrom include burning or stinging of the eye after you use the eye drops, dry or irritated eyes, puffy eyes, or watery eyes.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Cromolyn Sodium USP...........20 mg"},
                                {item: "Benzalkonium Chloride Solution USP......0.1mg"},
                                {item: "Aqueous Buffered Vehicle...........q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-allergic/3.webp",
                        },
                        {
                            name: "ALLERNIL-O",
                            model: "Pack: 5ml Pack",
                            modelImg: "",
                            descriptions: "Allernil-O Eye Drops (Olopatadine Hydrochloride Ophthalmic Solution) is a sterile ophthalmic solution containing olopatadine, a relatively selective H1-receptor antagonist and inhibitor of histamine release from the mast cell for topical administration to the eyes.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains : Olopatadine Hydrochloride IP",
                            compositionContents: [
                                {item: "Equivalent to Olopatadine......... 0.1% w/v"},
                                {item: "Benzalkonium Chloride Solution IP.........0.01%w/v"},
                                {item: "Sterile aqueous Vehicle..........q.s.Sterile aqueous Vehicle..........q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-allergic/4.png",
                        },
                        {
                            name: "ALLERNIL-HS Eye Drops",
                            model: "(Olopatadine Ophthalmic Solution IP)",
                            modelImg: "",
                            descriptions: "ALLERNIL-HS Eye Drops (Olopatadine Ophthalmic Solution IP) is a sterile ophthalmic solution containing olopatadine, a relatively selective H1-receptor antagonist and inhibitor ofhistamine release from the mast cell,for topical administration to the eyes.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Olopatadine Hydrochloride"},
                                {item: "eq. to Olopatadine U.S.P.                            0.2mg"},
                                {item: "Benzalkonium Chloride Solution I.P.          0.1mg (As preservative)"},
                                {item: "Sterile Aqueous Vehicle.                              q. s.."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-allergic/5.webp",
                        }
                    ]
                },
                {
                    id:"1306d10b-766a-4ce5-8c31-0c4eb8e2223f", 
                    libelle: "Eye Lubricants",
                    contents: [
                        {
                            name: "A-LUBGEL",
                            model: "Pack : 10ml Pack",
                            modelImg: "",
                            descriptions: "A-LUBGEL Eye Drops (Sodium Hyaluronate Eye Drops 0.1%) is sterile ophthalmic solution. Sodium Hyaluronate Eye Drops are also known as 'artificial tears'. They are used to relieve eye dryness and soreness. They moisten, soothe and lubricate the surface of your eye, making it feel more comfortable.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Sodium Hyaluronate BP     ....................0.1% w/v"},
                                {item: "Stabilized Oxychloro complex    .......0.05% w/v (As preservative)"},
                                {item: "Aqueous buffer vehicle    .......q.s"}
                            ],

                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/1.png",
                        },
                        {
                            name: "ART",
                            model: "Pack : 10ml Pack",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                               
                               {item: "Accurate measurements with external power reading"},
                               
                               {item: "Dual power supply system"},
                               
                               {item: "Clear green illumination target"},
                               
                               {item: "Energy-efficient LED illumination"},
                               
                               {item: "Possibility of accurate centering and marking of lenses"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/2.png",
                        },
                        {
                            name: "ART GEL",
                            model: "Pack : 10ml Pack",
                            modelImg: "",
                            descriptions: "ART GEL - Eye Drops (Carmellose Sodium Eye Drops IP) contain the active ingredient Carmellose Sodium, which is a type of medicine known as an eye lubricant. It is used for the treatment of the symptoms of dry eye (such as soreness, burning, irritation or dryness) caused by your eye not producing enough tears to keep the eye wet.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                { item: "Sodium Carboxy Methyl Cellulose IP .....10 mg"},
                                { item: "Stabilized Oxychloro complex .........0.0075% w/v (As preservative)"},
                                { item: "Sterile aqueous vehicle..........q.s.}"}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/3.png",
                        },
                        {
                            name: "ART PLUS",
                            model: "Pack : 10ml Pack",
                            modelImg: "",
                            descriptions: "ART PLUS - Eye Drops (Carmellose Sodium Eye Drops IP) contain the active ingredient Carmellose Sodium, which is a type of medicine known as an eye lubricant. It is used for the treatment of the symptoms of dry eye (such as soreness, burning, irritation or dryness) caused by your eye not producing enough tears to keep the eye wet.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Sodium Carboxy Methyl Cellulose IP ....5 mg"},
                                {item: "Stabilized Oxychloro complex .........0.005% w/v (As preservative)"},
                                {item: "Sterile aqueous vehicle.............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/4.png",
                        },
                        {
                            name: "C-ART",
                            model: "Pack : 10ml Pack",
                            modelImg: "",
                            descriptions: "C-ART -Eye Drops (Sodium Carboxy Methyl Cellulose & Glycerin Sterile Ophthalmic Solution)  contain the active ingredient Carmellose Sodium, which is a type of medicine known as an eye lubricant.It is used for the treatment of the symptoms of dry eye (such as soreness, burning, irritation or dryness) caused by your eye not producing enough tears to keep the eye wet.Eye Lubricants keep the eye moist, help to protect the eye from injury and infection, and decrease symptoms of dry eyes such as burning, itching, and feeling as if something is in the eye.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Sodium Carboxy Methyl Cellulose IP ......5 mg"},
                                {item: "Glycerin IP ...........0.5%v/v"},
                                {item: "Stabilized Oxychloro complex ........0.05mg (As preservative)"},
                                {item: "Aqueous Buffered vehicle...............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/5.png",
                        },
                        {
                            name: "Art FORTE",
                            model: "(Hypromellose,Glycerin & Dextran 70 Ophthalmic Solution)",
                            modelImg: "",
                            descriptions: "Art-Forte are primarily used to treat dry eyes. Dry eye(s) is a condition in which the eye fails to make enough tears to coat it, which exposes the cornea and conjunctiva (outer coverings of the eye) to evaporation and subsequent damage.",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [
                                {item: "Hypromellose USP         0.3% w/v"},
                                {item: "Dextran 70 USP             0.1% w/v"},
                                {item: "Glycerin USP                  0.2%w/v"},
                                {item: "Stabilized Oxychloro Complex                 0.005% w/v (As Preservative)"},
                                {item: "Aqueous Isotonic Solution               q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/6.webp",
                        },
                        {
                            name: "MEGALUB",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "MEGALUB- Eye Drops (Sodium Hyaluronate Eye Drops 0.18%) is sterile ophthalmic solution. Sodium Hyaluronate Eye Drops are also known as 'artificial tears'. They are used to relieve eye dryness and soreness. They moisten, soothe and lubricate the surface of your eye, making it feel more comfortable",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Sodium Hyaluronate BP .....................1.8 mg"},
                                {item: "Stabilized Oxychloro complex.....0.05% w/v ( As preservative )"},
                                {item: "Aqueous buffer vehicle........q.s"}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/7.png",
                        },
                        {
                            name: "ART Ultra Eye Drops",
                            model: "Art Ultra Eye Drops (10 ml)",
                            modelImg: "",
                            descriptions: "Polyethylene Glycol 400 & Propylene Glycol Eye Drops. For the use only of registered medical practitioners or a hospital or a laboratory",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Polyethylene Glycol400 IP            4 mg"},
                                {item: "Propylene Glycol IP                        3 mg"},
                                {item: "Sodium Perborate BP                    0.05mg (As Preservative)"},
                                {item: "Water  for Injection                             q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/8.webp",
                        },
                        {
                            name: "OCUTEAR",
                            model: "Pack : 10ml Pack",
                            modelImg: "",
                            descriptions: "Dry eyes are caused by decreased tear production/increased tear evaporation and an imbalance in the makeup of your tears.Common causes for dry eyes are wind, sun, heating/air conditioning, computer use/reading, and certain medication. These drops are generally prefered for the people having sensitive eyes. Eye lubricants keep the eye moist, help to protect the eye from injury and infection, and decrease the symptoms of dry eyes such as burning, itching, and feeling of something in the eye.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Povidone IP    .....6 mg"},
                                {item: "Polyvinyl Alcohol   ....14 mg"},
                                {item: "Chlorbutol IP  ........5 mg (As preservative)"},
                                {item: "Aqueous buffered vehicle  ...q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/9.png",
                        },
                        {
                            name: "CYLOPRO Eye Drops",
                            model: "(Cyclosporine Ophthalmic Emulsion 0.05% w/v)",
                            modelImg: "",
                            descriptions: "Cyclosporine is a fine white powder. Cylopro Eye Drops (Cyclosporine Ophthalmic Emulsion 0.05%w/v) appears as a white opaque to slightly translucent homogeneous emulsion. It has an osmolality of not less then 300 mOsmol/kg and a pH of 6.5-8.0.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Cyclosporine USP                  0.05% w/v"},
                                {item: "Benzalkonium Chloride Solution USP               0.02% w/v (As preservative)"},
                                {item: "Sterile Aqueous Vehicle            q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/10.webp",
                        },
                        {
                            name: "MEGALUB",
                            model: "Pack : 10ml Pack",
                            modelImg: "",
                            descriptions: "MEGALUB- Eye Drops (Sodium Hyaluronate Eye Drops 0.18%) is sterile ophthalmic solution. Sodium Hyaluronate Eye Drops are also known as 'artificial tears'. They are used to relieve eye dryness and soreness. They moisten, soothe and lubricate the surface of your eye, making it feel more comfortable.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Sodium Hyaluronate BP .....................1.8 mg"},
                                {item: "Stabilized Oxychloro complex.....0.05% w/v (As    preservative)"},
                                {item: "Aqueous buffer vehicle........q.s"}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/11.png",
                        },
                        {
                            name: "Goniolube",
                            model: "(Hypromellose Ophthalmic Solution 2.5% w/v)",
                            modelImg: "",
                            descriptions: "Goniolube is an isotonic, clear, colorless, viscoelastic solution. Goniolube contains methyl cellulose with a high molecular weight of > 80,000 daltons..",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Goniolube contains: ",
                            compositionContents: [
                                {item: "Hydroxypropyl  "},
                                {item: "Methylcellulose USP         2.5 % w/v"},
                                {item: "Sterile Isotonic Base                q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/12.webp",
                        },
                        {
                            name: "MEGALUB T",
                            model: "(Hypromellose Ophthalmic Solution 2.5% w/v)",
                            modelImg: "",
                            descriptions: "MEGALUB-T Eye Drops (Sodium Hyaluronate Eye Drops 0.18% w/v) is a natural polymer, which is also present in the structures of the human eye. the particular physical characteristic of Sodium Hyaluronate confer to this formulation its viscoelastic and water retaining properties. Sodium Hyaluronate provides a stable coating on the surface of the eye, which is slowly eliminated by blinking. Therefore, MEGALUB-T Eye Drops(Sodium Hyaluronate Eye Drops 0.18% w/v) is a combines long lasting relief with maximum comfort. It is well tolerated due to its unique composition.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Each mL contains:",
                            compositionContents: [
                                {item: "Sodium Hyaluronate BP                 1.8 mg"},
                                {item: "Stabilized Oxychloro                   0.05 % w/v (As preservative)"},
                                {item: "Aqueous Buffered Vehicle            q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/eye-lubricants/13.webp",
                        },
                        
                    ]
                },
                {
                    id:"b9dce222-0797-4b3f-a031-2ead0612b421", 
                    libelle: "Retinal Products",
                    contents: [
                        {
                            name: "BLUERET",
                            model: "Brilliant Blue G Solution 0.05% W/V",
                            modelImg: "",
                            descriptions: "BLUERET (Brilliant Blue G Solution 0.05% w/v) is a sterile, pyrogen free blue coloured aqueous solution. It selectively stains the ILM to facilitate the complete peeling, required to repair macula holes during the retinal surgery. It is a ready to use solution with a concentration of 0.05% w/v Brilliant Blue G in a Isotonic base.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Brilliant Blue G .......0.05%w/v"},
                                {item: "Sodium Chloride IP ........0.5% w/v"},
                                {item: "Sterile Isotonic base ......q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/retinal-products/1.png",
                        },
                    ]
                },
                {
                    id:"c24bb732-7ec2-448f-8602-bba6cc641518", 
                    libelle: "Anti - Bacterial",
                    contents: [
                        {
                            name: "MOXIFUR 1ml",
                            model: "Moxifloxacin Ophthalmic Solution USP",
                            modelImg: "",
                            descriptions: "Moxifur (Moxifloxacin Ophthalmic Solution USP) is a sterile 1 ml clear, pale yellow coloured, isotonic, preservative free ophthalmic solution.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Moxifloxacin Hydrochloride USP"},
                                {item: "Eq. to Moxifloxacin 0.5% w/v"},
                                {item: "Aqueous Buffered Vehicle q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial/1.png",
                        },
                        {
                            name: "MOXIFUR",
                            model: "Pack : 5 Ml Pack",
                            modelImg: "",
                            descriptions: "Moxifur- Eye Drops is a sterile topical ophthalmic solution. Moxifloxacin is a fourthgeneration fluoroquinolone antibacterial agent active against a broad spectrum of Grampositive and Gram-negative ocular pathogens, atypical microorganisms and anaerobes. Moxifur- Eye Drops is a clear, greenish-yellow solution formulated as a sterile, multipledose product, for topical ophthalmic use.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains : Moxifloxacin Hydrochloride IP",
                            compositionContents: [
                                {item: "Eq. to Moxifloxacin.     ......0.5%w/v."},
                                {item: "Sterile aqueous base.    .......q."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial/2.png",
                        },
                        {
                            name: "TOBOTOR",
                            model: "Pack : 5 Ml Pack",
                            modelImg: "",
                            descriptions: "Tobotor-Eye Drops is an antibiotic eye drop. Tobotor-Eye Drops is an aminoglycoside antibiotic that kills susceptible bacteria by blocking bacterial protein synthesis. Death of susceptible bacteria occurs because of the lack of functional proteins.Tobotor-Eye Drops treats only bacterial eye infections and does not work for other types of eye infections.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains:",
                            compositionContents: [
                                {item: "Tobramycin Sulphate USP"},
                                {item: "TEquivalent to Tobramycin...........3 mg"},
                                {item: "Benzalkonium Chloride Solution IP.............0.05 mg (As preservative)"},
                                {item: "Sterile Aqueous buffered vehicle..............q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial/3.png",
                        },
                        {
                            name: "Q4G",
                            model: "Pack : 5 Ml Pack",
                            modelImg: "",
                            descriptions: "Q4G-Eye Drops (Gatifloxacin Ophthalmic Solution) is used to treat infections of the eye, such as bacterial conjunctivitis. Gatifloxacin belongs to a group of medicines called fluoroquinolone antibiotics. It works by killing bacteria that cause conjunctivitis.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Gatifloxacin ..............3 mg"},
                                {item: "Benzalkonium Chloride Solution IP. ..........0.02 mg (As preservative)"},
                                {item: "Aqueous buffered vehicle.............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial/4.png",
                        },
                        {
                            name: "A-Genta Eye Drops",
                            model: "(Gentamicin Sulfate Ophthalmic Solution USP)",
                            modelImg: "",
                            descriptions: "A-Genta Eye Drops (Gentamicin Sulfate Ophthalmic Solution USP) is a sterile, topical anti- infective agent for ophthalmic use. The active ingredient, Gentamicin Sulfate, is a water- soluble antibiotic of the aminoglycoside group. Gentamicin is obtained from cultures of Micromonospora purpurea. It is a mixture of the sulfate salts of Gentamicin C1,C 2, and C 1A. All three components appear to have similar antimicrobial activities. Gentamicin Sulfate occurs as a white to buff powder and is soluble in water but insoluble in alcohol.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE"}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Gentamicin Sulfate USP"},
                                {item: "Eq. to Gentamicin 0.3%w/v"},
                                {item: "Benzalkonium Chloride Solution USP 0.02%w/v (As preservative)"},
                                {item: "Sterile Buffered Vehicle             q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial/5.png",
                        },
                        {
                            name: "Aflox",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "Aflox Eye Drops is a sterile ophthalmic solution. It is a fluorinated carboxyquinolone anti-infective for topical ophthalmic use. Ofloxacin Ophthalmic Solution is unbuffered and formulated with a pH range - 6.0 to 6.8.",
                            otherDescription: [],
                            compositionTitle: "Each Ml Contains:",
                            compositionContents: [
                                {item: " Ofloxacin I.P .................3 mg"},
                                {item: "Benzalkonium Chloride Solution I.P. ........0.05 mg (As preservative)"},
                                {item: "Aqueous buffered vehicle   ......................q. s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial/6.webp",
                        },
                        {
                            name: "Appachloro Eye Drops",
                            model: "Chloramphenicol Ophthalmic Solution USP",
                            modelImg: "",
                            descriptions: "Appachloro (Chloramphenicol Ophthalmic Solution USP) is a sterile ophthalmic solution. Appachloro (Chloramphenicol Ophthalmic Solution USP) contains an antibiotic called chloramphenicol. It is used to treat bacterial infections that affect the front surfaces of the eye. The most common type of infection in this area is called acute bacterial conjunctivitis. When you have acute bacterial conjunctivitis the white part of one or both of your eyes will be red and /or your eyelids will be red or swollen.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE"}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Chloramphenicol USP           0.5%w/v"},
                                {item: "Phenyl Mercuric"},
                                {item: "Nitrate USP    0.001%w/v  (As preservative)"},
                                {item: "Sterile Buffered Vehicle              q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial/7.webp",
                        },
                        {
                            name: "APPACIPRO Eye Drops",
                            model: "(Ciprofloxacin Ophthalmic Solution USP)",
                            modelImg: "",
                            descriptions: "Appacipro Eye Drops (Ciprofloxacin Ophthalmic Solution USP 0.3% w/v) is a synthetic, sterile, multiple dose, antimicrobial for topical ophthalmic use. Ciprofloxacin is a fluoroquinolone antibacterial active against a broad spectrum of gram-positive and gram-negative ocular pathogens. It is available as the monohydrochloride monohydrate salt of 1-cyclopropyl-6-fluoro-1,4-dihydro-4- oxo-7-(1-piperazinyl)-3- quinoline-carboxylic acid. It is a faint to light yellow crystalline powder with a molecular weight of 385.8. Its empirical   formula   is  C17H18FN3O3HClH2O. Ciprofloxacin differs from other quinolones in that it has a fluorine atom at the 6-position, a piperazine moiety at the 7-position, and a cyclopropyl ring at the 1-position.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE"}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Ciprofloxacin   Hydrochloride   USP"},
                                {item: "Eq. to Ciprofloxacin     0.3 % w/v"},
                                {item: "Benzalkonium Chloride Solution USP 0.02% w/v (As preservative)"},
                                {item: "Sterile Aqueous Vehicle            q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial/8.webp",
                        }
                    ]
                },
                {
                    id:"6519817d-53fa-4a07-8988-6089ee628f5c", 
                    libelle: "Anti - inflammatory",
                    contents: [
                        {
                            name: "PREDS",
                            model: "Pack : 5ml & 10ml Pack",
                            modelImg: "",
                            descriptions: "PREDS-Ophthalmic Suspension (Prednisolone acetate Ophthalmic Suspension USP) is synthetic analog of glucocorticoide hydrocortisone (cortisol), has an anti-inflammatory action for ophthalmic use.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Prednisolone Acetate IP ........................10 mg."},
                                {item: "Benzalkonium Chloride Solution IP ......0.2 mg (As preservative)"},
                                {item: "Sterile aqueous vehicle ........................q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-inflammatory/1.png",
                        },
                        {
                            name: "A-NAC Eye Drops",
                            model: "(Bromfenac Sodium Ophthalmic Solution)",
                            modelImg: "",
                            descriptions: "A-NAC Eye Drops (Bromfenac Sodium Ophthalmic Solution) is a sterile, topical, nonsteroidal anti-inflammatory drug (NSAID)for ophthalmic use. romfenac Sodium is designated chemically as sodium 2-amino-3-(4-bromobenzoyl) phenylacetate sesquihydrate, with an empirical formula of C15H11BrNNaO3. ",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Bromfenac Sodium Eq. to Bromfenac          0.9 mg"},
                                {item: "Benzalkonium Chloride SolutionIP                    0.1mg (As Preservative)"},
                                {item: "Aqueous Buffered  Vehicle q.s"},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-inflammatory/2.webp",
                        },
                        {
                            name: "Flurocot",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "Flurocot Eye Drops is an anti inflammatory agent inhibits the edema,fibrin deposition,capillary dilation and phagocytic migration of the acute inflammatory response, as well as capillary proliferation, deposition of collagen and scar formation.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Fluorometholone Acetate USP.........1 mg"},
                                {item: "Benzalkonium Chloride Solution IP .......0.1 mg (As preservative)"},
                                {item: "Sterile aqueous buffered vehicle............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-inflammatory/3.png",
                        },
                        {
                            name: "APPASONE Eye Drops",
                            model: "(Dexamethasone Sodium Phosphate Ophthalmic Solution USP 0.1% w/v)",
                            modelImg: "",
                            descriptions: "APPASONE Eye Drops (Dexamethasone Sodium Phosphate Ophthalmic Solution USP 0.1% w/v) is a clear, colorless to pale yellow topical steroid solution for ophthalmic administration. Glucocorticoids are adrenocortical steroids, both naturally occurring and synthetic. Dexamethasone is a synthetic analog of naturally occurring glucocorticoids (hydrocortisone and cortisone). Dexamethasone sodium phosphate is a water soluble, inorganic ester of dexamethasone. It is approximately three thousand times more soluble in water at 25°C than hydrocortisone. ",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Dexamethasone Sodium Phosphate  USP"},
                                {item: "Eq. to Dexamethasone Phosphate    0.1% w/v"},
                                {item: "Aqueous Buffered Vehicle                  q.s"},
                                {item: "(Solution Preservedwith Ionic Buffered System)"},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-inflammatory/4.webp",
                        },
                        {
                            name: "Aflur",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "Aflur- Eye Drops (Flurbiprofen eye drops) are used to keep the pupils of the eye from getting smaller (miosis) during an eye surgery. This medicine is a topical nonsteroidal anti-inflammatory drug (NSAID).",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Flurbiprofen Sodium IP........ 0.3 mg"},
                                {item: "Thimerosal IP............0.05 mg"},
                                {item: "Aqueous Buffered Vehicle........q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-inflammatory/5.png",
                        },
                        {
                            name: "LP PREDS 0.5 Eye Drops",
                            model: "(Loteprednol Etabonate Sterile Ophthalmic Suspension)",
                            modelImg: "",
                            descriptions: "LP PREDS 0.5 (Loteprednol Etabonate Sterile Ophthalmic Suspension) is contains a sterile, topical anti-inflammatory corticosteroid for ophthalmic use. Ophthalmic corticosteroid with minimal systemic absorption due to ocular metabolism. Used for seasonal allergic conjunctivitis and steroid responsive ocular diseases(e.g., cyclitis, GPC, iritis, keratitis). Also used for postoperative ocular pain and inflammation and for the signs and symptoms of dry eye disease.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each mL contains:",
                            compositionContents: [
                                {item: "Loteprednol Etabonate                  0.5%w/v"},
                                {item: "Benzalkonium Chloride Solution USP         0.01% v/v (As Preservative)"},
                                {item: "Sterile Aqueous Vehicle               q.s"}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-inflammatory/6.webp",
                        },
                        {
                            name: "PREDS",
                            model: "Pack : 5 & 10ml",
                            modelImg: "",
                            descriptions: "PREDS(Prednisolone Acetate Ophthalmic Suspension USP) is a synthetic analog of glucocorticoid hydrocortisone (cortisol), has an anti-inflammatory action for ophthalmic use. The chemical name of the active ingredient is Pregna-1, 4-diene-3, 20-dione, 21-(acetyloxy)-11, 17-dihydroxy-(11β)-preds is a white colored suspension.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each mL contains:",
                            compositionContents: [
                                {item: "Prednisolone Acetate USP                 10 mg"},
                                {item: "Benzalkonium Chloride Solution USP    0.2 mg (As preservative)"},
                                {item: "Sterile aqueous vehicle............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-inflammatory/7.webp",
                        },
                        {
                            name: "I-NAC",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "I-NAC Eye Drops is a sterile, topical, nonsteroidal anti-inflammatory (NSAID) prodrug for ophthalmic use. Each mL of I-NAC Eye Drops contains 1 mg of nepafenac. Nepafenac is designated chemically as 2-amino-3-benzoylbenzeneacetamide with an empirical formula of C15H14N2O2.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Nepafenac...................0.1 % w/v"},
                                {item: "Benzalkonium Chloride Solution IP.................0.01 %v/v (As preservative)"},
                                {item: "Sterile Aqueous vehicle..................q.s"}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-inflammatory/8.png",
                        },
                        {
                            name: "Ketorocin-0.5",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "Ketorocin 0.5 Eye Drops  is a member of the pyrrolo-pyrrole group of non-steroidal anti-inflammatory drugs ( NSAIDS ) for ophthalmic use .",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Ketorolac Tromethamine IP...............5 mg"},
                                {item: "Benzalkonium Chloride Solution I.P..........0.1 mg (As preservative)"},
                                {item: "Water for injection IP............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-inflammatory/9.png",
                        },
                        {
                            name: "Ketorocin-Ls",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "Ketorocin-LS Eye Drops is a member of the pyrrolo-pyrrole group of non-steroidal anti-inflammatory drugs ( NSAIDS ) for ophthalmic use.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Ketorolac Tromethamine IP...............4 mg"},
                                {item: "Stabilized Oxychloro complex..........0.05 mg (As preservative)"},
                                {item: "Water for injections IP............ .q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-inflammatory/10.webp",
                        }
                    ]
                },
                {
                    id:"f642f51a-23b1-4072-8be7-a663b121fe13", 
                    libelle: "Surgical Products",
                    contents: [
                        {
                            name: "APPAVISC HV PFS ®",
                            model: "(Hydroxypropyl Methylcellulose Ophthalmic Solution USP)",
                            modelImg: "",
                            descriptions: "APPAVISC HV PFS (Hydroxypropyl Methylcellulose Ophthalmic Solution USP) is sterile, a pyrogen free clear & transparent viscous solution of highly purified HPMC. The chemical name of active ingredient is Cellulose 2- Hydroxypropyl Methyl Ether. Each mL contains: Hydroxypropyl Methylcellulose USP 2% w/v & Sterile isotonic base q.s.",
                            otherDescription: [
                                {item: "PRESERVATIVE FREE STERILE SOLUTION"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/1.png",
                        },
                        {
                            name: "APPASIL",
                            model: "Silicone Oil 1000 mm²/s (cSt) (Polydimethylsiloxane BP)",
                            modelImg: "",
                            descriptions: "APPASIL(Purified Polydimethyl Siloxane) is highly purified long chain Polydimethyl Siloxane trimethyl siloxy terminated silicone oil. It is sterile, non-pyrogenic clear colorless and has a viscosity of NLT 1000 mPa·s.",
                            otherDescription: [],
                            compositionTitle: "Appasil Contain",
                            compositionContents: [
                                {item: "Silicone Oil 1000 mm2 cSt (Polydimethylsiloxane BP)"}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/2.webp",
                        },
                        {
                            name: "COHEVISC 1.0",
                            model: "Pack : 1ml Pack",
                            modelImg: "",
                            descriptions: "Cohevisc Ophthalmic Solution (Sodium Hyaluronate 1.0, 1.4 & 1.8) is a cohesive high quality viscoelastic formulation. It has high molecular weight, high pseudoplasticity, and high surface tension. Facilitates IOL implantation can be easily removed. GENERIC NAME: Sodium hyaluronate ophthalmic Solution",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Sodium Hyaluronate BP....10 mg"},
                                {item: "Sterile Isotonic Solution....q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/3.png",
                        },
                        {
                            name: "APPASIL PLUS BP",
                            model: "SiliconeOil 5000 mm²/s (cSt) (Polydimethylsiloxane BP)",
                            modelImg: "",
                            descriptions: "APPASIL PLUS is a sterile, highly purified long chain Polydimethyl Siloxane of the formula (CH3)3SiO- [(CH3)2SiO]n-Si(CH3)3.It is a clear colorless liquid at room temperature with a viscosity of NLT 5000mPa·s.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains : ",
                            compositionContents: [
                                {item: "Silicone Oil 5000 mm2 cSt (Polydimethylsiloxane BP)"}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/4.webp",
                        },
                        {
                            name: "COHEVISC 1.4",
                            model: "Pack : 1ml Pack",
                            modelImg: "",
                            descriptions: "Cohevisc Ophthalmic Solution (Sodium Hyaluronate 1.0, 1.4 & 1.8) is a cohesive high quality viscoelastic formulation. It has high molecular weight, high pseudoplasticity, and high surface tension. Facilitates IOL implantation can be easily removed. GENERIC NAME: Sodium hyaluronate ophthalmic Solution",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Sodium hyaluronate BP.....14mg"},
                                {item: "Sterile Isotonic Solution....q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/5.png",
                        },
                        {
                            name: "APPAVISC PFS 2.4 3mL",
                            model: "(Hydroxypropyl Methyl Cellulose Ophthalmic Solution USP) PRESERVATIVE FREE STERILE SOLUTION",
                            modelImg: "",
                            descriptions: "APPAVISC PFS 2.4 (Hydroxypropyl Methyl Cellulose Ophthalmic Solution USP) is sterile, apyrogen free clear & transparent viscous solution of highly purified HPMC. The chemical name of active ingredient is Cellulose 2-hydroxypropylmethyl ether. Each mL contains: Hydroxypropyl Methylcellulose USP 2.4%w/v & Sterile isotonic base q.s.  Appavisc PFS 2.4 solution is formulated to a viscosity of NLT 7000 cps.",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/6.webp",
                        },
                        {
                            name: "APPAVISC®",
                            model: "(Hydroxypropyl Methylcellulose Ophthalmic Solution USP)",
                            modelImg: "",
                            descriptions: "APPAVISC (Hydroxypropyl Methylcellulose Opthalmic Solution USP) is sterile, a pyrogen free clear & transparent viscous solution of highly purified HPMC. The chemical name of active ingredient is Cellulose 2-hydroxypropyl methyl ether. Each mL contains: Hydroxyp ropyl Methylcellulose USP 2% w/v & Sterile isotonic base q.s.",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/7.png",
                        },
                        {
                            name: "APPAVISC PFS ®",
                            model: "(Hydroxypropyl Methylcellulose Ophthalmic Solution USP)PRESERVATIVE FREE STERILE SOLUTION",
                            modelImg: "",
                            descriptions: "APPAVISC PFS 2.4 (Hydroxypropyl Methyl Cellulose Ophthalmic Solution USP) is sterile, a pyrogen free clear & transparent viscous solution of highly purified HPMC. The chemical name of active ingredient is Cellulose 2-Hydroxypropyl Methyl Ether. Each mL contains: Hydroxypropyl Methylcellulose USP 2.4%w/v & Sterile isotonic base q.s.  Appavisc PFS 2.4 solution is formulated to a viscosity of NLT 7000 cps.",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/8.webp",
                        },
                        {
                            name: "COHEVISC 1.8®",
                            model: "(Sodium Hyaluronate Sterile Ophthalmic Solution)",
                            modelImg: "",
                            descriptions: "Cohevisc 1.8 ophthalmic viscosurgical device is a sterile, non-pyrogenic, high molecular weight, non-inflammatory highly purified fraction of Sodium Hyaluronate dissolved in physiological sodium chloride, phosphate buffer. Each mL of Cohevisc 1.8 contains 18 mg of Sodium Hyaluronate in a sterile isotonic base. pH is adjusted with Sodium Hydroxide / Hydrochloric acid.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE ANDSAFETY INFORMATION, PLEASE READ CAREFULLYAND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/9.png",
                        },
                        {
                            name: "RHEX-ID",
                            model: "Pack : Each Ml Contains",
                            modelImg: "",
                            descriptions: "Rhex ID (Trypan Blue sterile solution preservative free) 0.8 mg is a sterile solution of Trypan Blue solution (an acid di-azo group dye). Rhex ID (Trypan Blue solution) is a selective tissue staining agent for use as a medical aid in ophthalmic surgery. Each mL of Rhex ID (Trypan Blue solution) sterile solution contains not more than 0.8 mg of Trypan Blue and 8.2 mg of Sodium Chloride in a aqueous buffered.It is a blue dye (Sodium ditolyldisazobis-8-amino-1-naphthol-3,6-disulfonate) used to stain capsules and has very low toxicity profile. It is available as a 0.1% solution.GENERIC NAME: Trypan Blue Sterile Solutions",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Trypan Blue ............0.8mg"},
                                {item: "Sodium Chloride USP.......8.2mg"},
                                {item: "Aqueous Buffered Vehicle......q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/10.png",
                        },
                        {
                            name: "SUPRACOAT",
                            model: "AA",
                            modelImg: "",
                            descriptions: "SUPRACOAT is a sterile, non-pyrogenic, viscoelastic solution of a highly purified non- inflammatory medium molecular weight fraction of sodium chondroitin sulphate and sodium hyaluronate. SUPRACOAT solution is formulated to a viscosity of 20000 to 60000 cps (at shear rate of 2 sec.-1 25°C). Each mL of SUPRACOAT Solution contains not more than 40 mg sodium chondroitin sulphate and 17 mg sodium hyaluronate in a physiological buffer. Sodium chondroitin sulphate and sodium hyaluronate are quite similar in regard to chemical and physical composition, as each occurs as a large, unbranched chain structure of medium to high molecular weight.",
                            otherDescription: [
                                {item: "Sodium Chondroitin Sulphate & Sodium Hyaluronate Ophthalmic Solution"}
                            ],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Sodium chondroitin sulphate   USP ............40 mg"},
                                {item: "Sodium hyaluronate     BP ............17 mg"},
                                {item: "Aqueous Buffered Solution .............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/11.png",
                        },
                        {
                            name: "APPAVISC PFS ® / 2mL",
                            model: "(Hydroxypropyl Methylcellulose Ophthalmic Solution USP)",
                            modelImg: "",
                            descriptions: "APPAVISC PFS (Hydroxypropyl Methylcellulose Ophthalmic Solution USP) is sterile, a pyrogen free clear &transparent viscous solution of highly puriﬁed HPMC. The chemical name of active ingredient is Cellulose 2- Hydroxypropyl Methyl Ether. Each mL contains: Hydroxypropyl Methylcellulose USP 2% w/v& Sterile isotonic base q.s.",
                            otherDescription: [
                                {item: "PRESERVATIVE FREE STERILE SOLUTION"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/12.png",
                        },
                        {
                            name: "APPARET BLUE",
                            model: "Trypan Blue Sterile Solution",
                            modelImg: "",
                            descriptions: "Apparet Blue is a tissue dye for use as a medical aid in ophthalmic surgery, enables visualization of connective tissue structures. It is a sterile non-inflammatory solution of trypan blue dissolved in sodium chloride and buffer solution.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Trypan Blue                        1.5 mg"},
                                {item: "Aqueous Buffered Vehicle  q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/surgical-products/13.webp",
                        },
                        
                    ]
                },
                {
                    id:"f39a2133-cc00-492c-aa7b-1fab9d6aeadc", 
                    libelle: "Ocular injection",
                    contents: [
                        {
                            name: "TRI-ACE",
                            model: "(Triamcinolone Acetonide Injectable Suspension USP)",
                            modelImg: "",
                            descriptions: "Tri-Ace (Triamcinolone Acetonide Injectable Suspension USP) is a synthetic glucocorticoid corticosteroid with anti-inflammatory action. This formulation is suitable for Intravitreal, intra- muscular and intra-articular use. This formulation is not for intravenous injection.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ml contains:",
                            compositionContents: [
                                {item: "Triamcinolone           Acetonide"},
                                {item: "USP                             40mg"},
                                {item: "In Sterile Aqueous Base   q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/ocular-injection/1.webp",
                        },
                        {
                            name: "APPACARPINE",
                            model: "(Pilocarpine Nitrate Ophthalmic Solution USP 0.5%w/v)",
                            modelImg: "",
                            descriptions: "APPACARPINE (Pilocarpine Nitrate Ophthalmic Solution USP 0.5%w/v) is a clear, colour less sterile ophthalmic solution. Not for injection. For Intracameral administration. Its contains no preservative. It is used as direct acting miotic.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ml contains:",
                            compositionContents: [
                                {item: "Pilocarpine Nitrate USP      0.5%w/v"},
                                {item: "Aqueous Vehicle                     q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/ocular-injection/2.webp",
                        },
                         {
                            name: "MYDRIA PFS",
                            model: "(Tropicamide, Phenylephrine Hydrochloride and Lidocaine Hydrochloride Injection) (Preservative Free Sterile Solution)",
                            modelImg: "",
                            descriptions: "Mydria PFS Injection is a solution which is injected into the eye.it contains three active substances:-Tropicamide which belongs to a group of medicines blocking the passage of impulses through particular nerves (known as anticholinergics),-Phenylephrine Hydrochloride Which belongs to a group of medicines mimicking the effects of impulses conveyed through particular nerves(known as alpha sympathomimetics).Lidocaine Hydrochloride which belongs to a class of drugs called amide type local anesthetics",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ml contains:",
                            compositionContents: [
                                {item: "Tropicamide IP                  0.2mg"},
                                {item: "Phenylephrine Hydrochloride IP                3.1mg"},
                                {item: "Lidocaine Hydrochloride IP              10mg"},
                                {item: "Water for Injections                 q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/ocular-injection/3.webp",
                        }
                        
                    ]
                },
                {
                    id:"d8ac63d4-4967-4c7a-b949-4ce78b74944a", 
                    libelle: "Anti - Bacterial & Anti - Inflammatory",
                    contents: [
                        {
                            name: "MOXIFUR-LP",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "Moxifur-LP Eye Drops contains Loteprednol Etabonate 0.5% and Moxifloxacin 0.5%. Loteprednol etabonate (LE) is a   soft   steroid used for the treatment of steroid responsive inflammatory conditions of the eye such as allergic conjunctivitis, uveitis, iritis, cyclitis, and selected infective conjunctivitis. Loteprednol etabonate has good ocular permeation properties. Moxifloxacin is a quinolone/fluoroquinolone antibiotic. It is a broad-spectrum antibiotic that is active against both Gram-positive and Gram-negative bacteria.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains : Moxifloxacin HCl IP",
                            compositionContents: [
                                {item: " Eq to Moxifloxacin...............0.5%"},
                                {item: "• Loteprednol Etabonate ...............0.5%"},
                                {item: "• Benzalkonium Chloride Solution I.P................0.02%v/v (As preservative)"},
                                {item: "• Sterile aqueous vehicle..................q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial-anti-inflammatory/1.png",
                        },
                        {
                            name: "AFLOX-D* Eye Drops",
                            model: "(Ofloxacin and Dexamethasone Ophthalmic Solution)",
                            modelImg: "",
                            descriptions: "AFLOX-D Eye Drops (Ofloxacin and Dexamethasone Ophthalmic Solution) is a clear, pale yellow color solution, multiple dose antibiotic and steroid combination for ophthalmic use",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE"}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Ofloxacin  USP          3mg"},
                                {item: "Dexamethasone Sodium Phosphate   USP         1 mg"},
                                {item: "Benzalkonium Chloride Solution USP 0.1 mg (As preservative)"},
                                {item: " Sterile Aqueous Vehicle                q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial-anti-inflammatory/2.webp",
                        },
                        {
                            name: "TOBOTOR-DM",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "Tobotor-Dm Eye Drops is a sterile, multiple dose antibiotic and steroid combination for topical ophthalmic use. Each mL of Tobotor-Dm Eye Drops contains: Actives: tobramycin 0.3% (3 mg) and Dexamethasone 0.1% (1 mg). Preservative: Benzalkonium Chloride 0.01%.",
                            otherDescription: [],
                            compositionTitle: " Label Claim: Each ML Contains : Tobramycin Sulphate USP",
                            compositionContents: [
                                {item: "Equivalent to Tobramycin.......3 mg"},
                                {item: "• Dexamethasone Sodium Phosphate I.P.............1 mg"},
                                {item: "• Chlorbutol IP. ........................................5 mg (As preservative)"},
                                {item: "• Aqueous buffered vehicle...........q.s"}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial-anti-inflammatory/3.png",
                        },
                        {
                            name: "MOXIFUR-P Eye Drops",
                            model: "Moxifloxacin & Prednisolone Acetate Sterile Ophthalmic Suspension",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Moxifloxacin Hydrochloride IP"},
                                {item: "Eq. to Moxifloxacin                  0.5% w/v"},
                                {item: "Prednisolone Acetate IP          1%w/v  "},
                                {item: "Benzalkonium Chloride Solution IP 0.02% w/v (As preservative)"},
                                {item: "Sterile Aqueous Vehicle                                 q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial-anti-inflammatory/4.webp",
                        },
                        {
                            name: "Q4G-DM Eye Drops",
                            model: "(Gatiﬂoxacin & Dexamethasone Eye Drops)",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "Composition: Each ML Contains :",
                            compositionContents: [
                                {item: "Gatifloxacin           3mg"},
                                {item: "Dexamethasone Sodium Phosphate IP"},
                                {item: "Eq. to Dexamethasone  Phosphate            1 mg "},
                                {item: "Benzalkonium Chloride Solution IP 0.01% v/v (As preservative)"},
                                {item: "Aqueous Buffered Vehicle         q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial-anti-inflammatory/5.webp",
                        },
                        {
                            name: "TOBOTOR-LP",
                            model: "(Loteprednol Etabonate and Tobramycin Sulphate Ophthalmic Suspension)",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETYINFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Loteprednol Etabonate         5mg"},
                                {item: "Tobramycin Sulphate USP"},
                                {item: "Eq. to Tobramycin                 3 mg "},
                                {item: "Benzalkonium Chloride Solution IP 0.02% w/v (As preservative)"},
                                {item: "Sterial Aqueous Buffered Vehicle              q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial-anti-inflammatory/6.webp",
                        },
                        {
                            name: "Moxifur-Dm",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "Moxifur-K Eye Drops is indicated for the reduction of post-operative inflammatory conditions of the eye.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains:",
                            compositionContents: [
                                {item: "Moxifloxacin Hydrochloride IP Eq to Moxifloxacin ...............5.0 mg"},
                                {item: "Dexamethasone Sodium Phosphate IP Eq to Dexamethasone Phosphate..........1.0mg"},
                                {item: "Benzalkonium Chloride Solution IP........0.2 mg"},
                                {item: "Aqueous Buffered Vehicle ..........q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial-anti-inflammatory/7.png",
                        },
                        {
                            name: "Moxifur-K",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "Moxifur-K Eye Drops  is indicated for the reduction of post-operative inflammatory conditions of the eye. Moxifur-K Eye Drops is a medicine that is used for the treatment of Intense Pain, Pain in Eye after eye surgery, swelling in eye after eye surgery, respiratory tract infections, abdominal infections, female genital tract infection and other conditions. Moxifur-K Eye Drops contains Ketorolac, and Moxifloxacin as active ingredients. Moxifur-K Eye Drops works by blocking the effect of chemicals causing pain; inhibiting enzymes involved in DNA processes;",
                            otherDescription: [
                                {item: "Eq. Moxifloxacin............. 0.5% w/v"},
                                {item: "Ketorolac Tromethamine IP 0.5% w/v"},
                                {item: "Benzalkonium chloride solution IP 0.01% v/v (As preservative)"},
                                {item: "Aqueous buffered vehicle...............q.s."}
                            ],
                            compositionTitle: "Label Claim: Each ML Contains : Moxifloxacin Hydrochloride IP",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-bacterial-anti-inflammatory/8.webp",
                        }
                    ]
                },
                {
                    id:"a887de18-89b0-4aad-9531-f9a93ee119de", 
                    libelle: "Mydriatics & Cycloplegics",
                    contents: [
                        {
                            name: "C-Pent",
                            model: "Pack : 5ml",
                            modelImg: "",
                            descriptions: "C-PENT Eye Drops is a potent para-sympatholytic drug of great value in including cycloplegia and wide mydriasis having rapid onset, shorter duration and greater intensity.",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: " Cyclopentolate Hydrochloride IP. ..........10 mg"},
                                {item: "● Benzalkonium Chloride Solution IP. ...........0.02 %v/v (As preservative)"},
                                {item: "● Aqueous buffered vehicle ............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/mydriatics-cycloplegics/1.png",
                        },
                        {
                            name: "APPAMIDE",
                            model: "Pack : 5ml",
                            modelImg: "",
                            descriptions: "Appamide Eye Drops is an anticholinergic prepared as a sterile topical ophthalmic solution in two strengths.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Tropicamide IP ............10 mg"},
                                {item: "Chlorbutol IP. ...............5 mg (As preservative)"},
                                {item: "Aqueous buffered vehicle.........q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/mydriatics-cycloplegics/2.png",
                        },
                        {
                            name: "Appamide Plus Eye Drops",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "APPAMIDE PLUS® Eye Drops (Tropicamide & Phenylephrine Hydrochloride Ophthalmic Solution) is a combination of a mydriatic/cycloplegic drug. Phenylephrine is a synthetic sympathomimetic (adrenergic mydriatic following topical application, phenylephrine brings about contraction of the radial muscle thereby bringing about dilatation of the pupil. Tropicamide is a parasympatholytic drug which inhibits the contraction of the circular muscle and also paralyses the ciliary muscle. Tropicamide functions as a cycloplegic/mydriatic drug.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLYAND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Tropicamide USP                           8 mg"},
                                {item: "Phenylephrine Hydrochloride USP 50 mg"},
                                {item: "Chlorobutanol USP                       5mg (As Preservative)"},
                                {item: "Aqueous Buffered Vehicle               q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/mydriatics-cycloplegics/3.png",
                        },
                        {
                            name: "A-Tropin Eye Drops",
                            model: "(Atropine Sulphate Ophthalmic Solution1% w/v)",
                            modelImg: "",
                            descriptions: "Benzeneacetic acid,ɑ-(hydroxymethyl),8-methyl-8-azabicyclo-(3.2.1) Oct-3-yl ester, endo-(+)-, Sulphate(2:1) (salt),monohydrate.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Atropine Sulphate IP 1.0 % w/v"},
                                {item: "Phenyl Mercuric Nitrate IP            0.001%w/v ( As preservative)"},
                                {item: "Aqueous Buffered Vehicle q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/mydriatics-cycloplegics/4.webp",
                        },
                        
                    ]
                },
                {
                    id:"c4gdc7d2-6747-4ffd-859c-92f2574c3b6e", 
                    libelle: "Injection",
                    contents: [
                        {
                            name: "A-Ligcaine 1.0/2.0",
                            model: "(Lidocaine Hydrochloride Injection USP)",
                            modelImg: "",
                            descriptions: "A-Ligcaine (Lidocaine Hydrochloride Injection USP) is a local anesthetic which is a sterile, nonpyrogenic solution intended for parenteral injection. Lidocaine hydrochloride is chemically designated as 2-(Diethylamino)-2, ",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each mL Contains:",
                            compositionContents: [
                                {item: "Lidocaine Hydrochloride USP        10 mg"},
                                {item: "Sodium Chloride USP     7 mg"},
                                {item: "Methylparaben USP       1 mg (As Preservative)"},
                                {item: "Water for Injection            q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/injection/1.webp",
                        },
                        {
                            name: "A-Ligcaine Plus",
                            model: "(Lidocaine Hydrochloride Injection USP)",
                            modelImg: "",
                            descriptions: "A-Ligcaine Plus(Lidocaine Hydrochloride & Epinephrine Injection USP) containing a local anesthetic agent, Lidocaine Hydrochloride, and a vasoconstrictor, Epinephrine (as bitartrate) and are administered parenterally by injection. The solutions contain lidocaine hydrochloride which is chemically designated asacetamide, 2-(diethylamino)-N-(2,6-dimethylphenyl)-monohydrochloride, and Epinephrine is ( - )-3,4-Dihydroxy-α- [(Methylamino) methyl] benzyl alcoho",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each mL Contains:",
                            compositionContents: [
                                {item: "Lidocaine Hydrochloride USP 20 mg"},
                                {item: "Epinephrine Bitartrate USP 0.01 mg (As Base)"},
                                {item: "Sodium Chloride USP              6.5 mg"},
                                {item: "Potassium Metabisulfite USP 1.2 mg"},
                                {item: "Disodium Edetate USP      0.25mg"},
                                {item: "Water for Injection                q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/injection/2.webp",
                        },
                        {
                            name: "AFLUOR",
                            model: "(Fluorescein Sodium Injection IP)",
                            modelImg: "",
                            descriptions: "AFLUOR (Fluorescein Sodium Injection IP) is a sterile solution for use intravenously as a diagnostic aid. Its chemical name is spiro[isobenzofuran-1 (3H),9'-[9H]xanthene]-3-one,3'6'-dihydroxy, disodium salt.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each mL Contains:",
                            compositionContents: [
                                {item: "Fluorescein Sodium IP        200 mg"},
                                {item: "Water for InjectionIP                q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/injection/3.webp",
                        },
                        {
                            name: "AFLUOR-10 /3mL",
                            model: "(Fluorescein Injection USP)",
                            modelImg: "",
                            descriptions: "AFLUOR-10 (Fluorescein Injection USP) is a sterile solution for use intravenously as a diagnostic aid. Its chemical name is spiro[isobenzofuran-1 (3H),9'-[9H]xanthene]-3-one,3'6'-dihydroxy, disodium salt.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each mL Contains:",
                            compositionContents: [
                                {item: "Fluorescein Sodium USP        100 mg"},
                                {item: "Water for Injection USP                q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/injection/4.webp",
                        },
                        {
                            name: "APPASONE /2mL",
                            model: "(Dexamethasone Sodium Phosphate Injection USP)",
                            modelImg: "",
                            descriptions: "APPASONE (Dexamethasone Sodium Phosphate Injection USP) is a water-soluble inorganic ester of Dexamethasone which produces a rapid response even when injected intramuscularly. Dexamethasone Sodium Phosphate, C22H28FNa2O8P,has a molecular weight of 516.41 and chemically is Pregn-4-ene-3, 20- dione,9-fluoro-11, 17-dihydroxy-16-methyl-21 (phosphonooxy)-, disodium salt, (11β, 16α). It occurs as a white to creamy white powder, is exceedingly hygroscopic, is soluble in water and its solutions have a pH between 7.0 and 8.5.It has",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each mL Contains:",
                            compositionContents: [
                                {item: "Dexamethasone Sodium Phosphate USP Eq. to Dexamethasone Phosphate 4 mg"},
                                {item: "Methylparaben USP    0.15% w/v PropylparabenUSP   0.02% w/v (As preservatives)"},
                                {item: "Water for Injection USP           q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/injection/5.webp",
                        },
                        {
                            name: "A-EXARIN",
                            model: "20mg/40mg/60mg/80mg/100mg. (Enoxaparin Sodium Injection USP)",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/injection/6.webp",
                        },
                    ]
                },
                {
                    id:"b3fdc7d2-6747-4ffd-819c-87f2502c3a9b", 
                    libelle: "Corneal Cross - Linking",
                    contents: [
                        {
                            name: "K-Link H",
                            model: "Riboflavin-5-Phosphate 0.1%W/V",
                            modelImg: "",
                            descriptions: "K-Link H (Riboflavin-5-Phosphate 0.1%w/v) is intended for topical ophthalmic administration as part of corneal collagen cross-linking with the KXL System. K-LinK H (Riboflavin-5-Phosphate 0.1% w/v) is yellow sterile buffered solution containing 1.0 mg/mL Riboflavin-5-Phosphate. The pH of the solution is approximately 7.1 and the osmolality is 155-175 mOsm/kg. Each 1 mL solution contains Riboflavin-5-Phosphate Sodium IP equivalent to Riboflavin 1.0 mg. The chemical formula for Riboflavin-5-Phosphate Sodium (Vitamin B2)is C17H20N4NaO9P with a molecular mass of 478.33 g/mol.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Riboflavin -5- Phosphate Sodium IP"},
                                {item: "Equivalent to Riboflavin ...........1.0 mg"},
                                {item: "Sterile Hypotonic Solution............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/corneal-cross-linking/1.webp",
                        },
                        {
                            name: "K-LINK",
                            model: "(Riboflavin and Dextran Sterile Solution)",
                            modelImg: "",
                            descriptions: "K-Link (Riboflavin& Dextran Sterile Solution) is intended for ophthalmic administration as part of corneal collagen cross-linking with the KXL System. K-Link (Riboflavin & Dextran Sterile Solution) is pale yellow to yellow sterile buffered solution containing 1.0 mg/mL Riboflavin-5-Phosphate & Dextran 500 200mg. The pH of the solution is approximately 7.0 and the osmolality is 285-350 mOsm/kg. Each 1 mL solution contains Riboflavin- 5-Phosphate Sodium equivalent to Riboflavin IP 1.0 mg & Dextran500 200mg.Please refer to the KXL System Operator’s Manual for a specific device description and instructions.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each mL contains",
                            compositionContents: [
                                {item: "Riboflavin -5- Phosphate Sodium"},
                                {item: "Equivalent to Riboflavin    IP 1.0 mg"},
                                {item: "Dextran 500                        200 mg"},
                                {item: "Sterile Isotonic Solutions            q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/corneal-cross-linking/2.webp",
                        },
                        {
                            name: "K-LINK H/ 2mL",
                            model: "(Riboflavin and Dextran Sterile Solution)",
                            modelImg: "",
                            descriptions: "K-Link H (Riboflavin-5-Phosphate 0.1%w/v) is intended for topical ophthalmic administration as part of corneal collagen cross-linking with the KXL System. K-LinK H (Riboflavin-5-Phosphate 0.1% w/v) is yellow sterile buffered solution containing 1.0 mg/mL Riboflavin-5-Phosphate. The pH of the solution is approximately7.1 and the osmolality is 155-175 mOsm/kg. Each1 mL solution contains Riboflavin-5-Phosphate Sodium IP equivalent to Riboflavin 1.0 mg. The chemical formula for Riboflavin-5-Phosphate Sodium (Vitamin B2) is C17H20N4NaO9P with a molecular mass of 478.33g/mol.",
                            otherDescription: [],
                            compositionTitle: "Each mL contains",
                            compositionContents: [
                                {item: "Riboflavin -5- Phosphate Sodium IP"},
                                {item: "Equivalent to Riboflavin     1.0 mg"},
                                {item: "‍Sterile tonic Solutions            q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/corneal-cross-linking/3.webp",
                        },
                        {
                            name: "K-Link Plus",
                            model: "Riboflavin Sterile Solution",
                            modelImg: "",
                            descriptions: "K-Link Plus (Riboflavin Sterile Solution) is intended for ophthalmic administration as part of corneal collagen cross-linking with the KXL System.K-Link (Riboflavin Sterile Solution) is pale yellow to yellow sterile buffered solution containing 1.0 mg/mL Riboflavin-5-Phosphate. The pH of the solution is approximately 7.0 and the osmolality is 285-400 mOsm/kg. Each 1 mL solution contains Riboflavin-5- Phosphate Sodium equivalent to Riboflavin IP 1.0 mg.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Riboflavin-5-Phosphate Sodium IP"},
                                {item: "equivalent to Riboflavin...........1.0 mg"},
                                {item: "Sterile Isotonic Solution ............q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/corneal-cross-linking/4.webp",
                        }
                    ]
                },
                {
                    id:"8f652a40-277c-4153-a66b-6c6051f06320", 
                    libelle: "Presbyopia",
                    contents: [
                        {
                            name: "A-BYOPIN EYE DROPS /5mL",
                            model: "(Pilocarpine Hydrochloride Ophthalmic Solution USP 1.25%w/v)",
                            modelImg: "",
                            descriptions: "A-BYOPIN Eye Drops(Pilocarpine Hydrochloride Ophthalmic Solution USP 1.25% w/v) is a cholinergic muscarinic receptor agonist prepared as an isotonic, colorless, sterile ophthalmic solution containing 1.25% of Pilocarpine Hydrochloride. The chemical name for pilocarpine hydrochloride is ( 3 S, 4 R)- 3 - ethyl-4 - [ ( 1 - methyl-1 H- imidazol- 5 - yl) methyl] oxolan- 2 - one hydrochloride. Its molecular weight is 244.72 and its molecular formula is C11H16N2O2 · HCl.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLYAND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Pilocarpine Hydrochloride USP                  12.5mg"},
                                {item: "Benzalkonium Chloride Solution USP    0.0075% w/v (As Preservative)"},
                                {item: "Sterile Aqueous Vehicle             q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/presbyopia/1.webp",
                        },
                    ]
                },
                {
                    id:"1bf38cbf-cd31-4be9-8246-41f24c95862f", 
                    libelle: "Anti Infective",
                    contents: [
                        {
                            name: "APIDINE PLUS Eye Drops‍",
                            model: "(Povidone-Iodine Sterile Ophthalmic Prep Solution)",
                            modelImg: "",
                            descriptions: "APIDINE PLUS(Povidone-Iodine Sterile Ophthalmic Prep Solution) is a broad-spectrum microbicide with the chemical formulas: 2-pyrrolidinone, 1- ethenyl-, homopolymer, compound with iodine; 1-vinyl-2-pyrrolidinone polymer, compound with iodine.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE"}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Povidone lodine IP        50 mg (Available lodine   0.5%  w/v)"},
                                {item: "Water for Injections IP              q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-infective/1.webp",
                        }
                    ]
                },
                {
                    id:"752dae34-439c-44ff-8b34-d86cb2dd8421", 
                    libelle: "Local Anesthesia",
                    contents: [
                        {
                            name: "B-CAINE Local Anesthetic",
                            model: "(Bupivacaine Hydrochloride Injection USP 0.5% W/V)",
                            modelImg: "",
                            descriptions: "B-CAINE (Bupivacaine Hydrochloride Injection USP 0.5% w/v) is used as a local or regional anesthetic for various procedures. Its safety and effectiveness depend on proper dosage, correct administration and readiness for emergencies. Bupivacaine is a prescription medication used to prevent pain and induce spinal anesthesia. Bupivacaine belongs to a group of drugs called local anesthetics. These work by numbing one small area of the body (local) or blocking pain in an area of the body (regional). This medication is available in an injection form to be administered by injection via local infiltration, peripheral nerve block, or caudal and lumbar blocks by a healthcare professional.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Bupivacaine Hydrochloride USP Eq. to anhydrous"},
                                {item: "Bupivacaine Hydrochloride......... 5.0 mg"},
                                {item: "Methylparaben USP............. 1.0 mg (As preservative)"},
                                {item: "Water for Injection USP......... q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/local-anesthesia/1.png",
                        },
                        {
                            name: "ACAINE-T Eye Drops",
                            model: "(Tetracaine Hydrochloride Ophthalmic Solution USP 0.5%w/v)",
                            modelImg: "",
                            descriptions: "Acaine-T Eye Drops (Tetracaine Hydrochloride Ophthalmic Solution USP 0.5% w/v) is a sterile, clear, colorless, topical local anesthetic for ophthalmic use only containing tetracaine hydrochloride as the active pharmaceutical ingredient. Inactive ingredients: Boric Acid, Potassium Chloride, Di-Sodium Edatate, Water For Injection. Sodium Hydroxide and/or Hydrochloric Acid may be added to adjust pH (3.7 – 6.0).",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Tetracaine Hydrochloride USP            0.5% w/v"},
                                {item: "Benzalkonium Chloride Solution USP          0.02% w/v (As Preservative)"},
                                {item: "Aqueous Base          q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/local-anesthesia/2.png",
                        },
                        {
                            name: "Lidovisc",
                            model: "(Sodium Hyaluronate &Lidocaine Hydrochloride Ophthalmic Solution)",
                            modelImg: "",
                            descriptions: "Lidovisc ophthalmic Solution is a sterile, non-pyrogenic, high molecular weight, noninflammatory highly purified fraction of Sodium Hyaluronate (1.5% w/v) and Lidocaine Hydrochloride (1.% w/v) dissolved in physiological phosphate buffer. Lidovisc is the first ophthalmic solution that offers an ancillary anesthetic and an ophthalmic solution combined; providing more comfort for your patient, especially during prolonged procedures.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/local-anesthesia/3.png",
                        },
                        {
                            name: "A-CAINE",
                            model: "Pack : 5 Ml Pack",
                            modelImg: "",
                            descriptions: "A-CAINE EYE DROPS is a local anesthetic for ophthalmic instillation. Each mL of sterile, aqueous solution contains: Active: Proparacaine Hydrochloride 5 mg (0.5%). Preservative: Chlorbutal 5 mg (0.5%).",
                            otherDescription: [],
                            compositionTitle: "Label Claim: Each ML Contains :",
                            compositionContents: [
                                {item: "Proparacaine Hydrochloride U.S.P ............0.5% w/v"},
                                {item: "Chlorbutol I.P. ...........................................0.5% w/v (as preservative"},
                                {item: "Aqueous Base .................................q.s"}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/local-anesthesia/4.png",
                        },
                        {
                            name: "ACAINE-T/5mL Eye Drops",
                            model: "(Tetracaine Hydrochloride Ophthalmic Solution USP 0.5%w/v)",
                            modelImg: "",
                            descriptions: "Acaine-T Eye Drops (Tetracaine Hydrochloride Ophthalmic Solution USP 0.5% w/v) is a sterile, clear, colorless, topical local anesthetic for ophthalmic use only containing tetracaine hydrochloride as the active pharmaceutical ingredient. Inactive ingredients: Boric Acid, Potassium Chloride, Di-Sodium Edatate, Water For Injection. Sodium Hydroxide and/or Hydrochloric Acid may be added to adjust pH (3.7 – 6.0).",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Tetracaine Hydrochloride USP                     0.5% w/v"},
                                {item: "Benzalkonium Chloride Solution USP          0.02% w/v (As Preservative)"},
                                {item: "Aqueous Base                   q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/local-anesthesia/5.webp",
                        },
                        
                    ]
                },
                {
                    id:"10902747-6c50-4ab7-803c-fde76f69f11b", 
                    libelle: "Antifungal",
                    contents: [
                        {
                            name: "APPASOL - 5",
                            model: "(Sodium Chloride Ophthalmic Solution USP)",
                            modelImg: "",
                            descriptions: "APPASOL-5 Eye Drops(Sodium Chloride Ophthalmic Solution USP 5% w/v) is a sterile, nonpyrogenic, hypertonic solution. The pH may have been adjusted with hydrochloric acid or NaoH.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLYAND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Sodium Chloride USP                 5% w/v"},
                                {item: "Methylparaben USP                  0.023%w/v"},
                                {item: "PropylparabenUSP                  0.01% w/v"},
                                {item: "Aqueous Buffered Vehicle               q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/antifungal/1.png",
                        }
                    ]
                },
                {
                    id:"f80b78df-48af-47cc-a989-488c54d55a8d", 
                    libelle: "Hypertonic Saline Solution",
                    contents: [
                        {
                            name: "APPASOL - 5",
                            model: "(Sodium Chloride Ophthalmic Solution USP)",
                            modelImg: "",
                            descriptions: "APPASOL-5 Eye Drops(Sodium Chloride Ophthalmic Solution USP 5% w/v) is a sterile, nonpyrogenic, hypertonic solution. The pH may have been adjusted with hydrochloric acid or NaoH.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLYAND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Sodium Chloride USP                 5% w/v"},
                                {item: "Methylparaben USP                  0.023%w/v"},
                                {item: "PropylparabenUSP                  0.01% w/v"},
                                {item: "Aqueous Buffered Vehicle               q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/hypertonic-saline-solution/1.png",
                        }
                    ]
                },
                {
                    id:"bbda0912-cb62-49fe-bfe6-71974a103864", 
                    libelle: "Anti - Glaucoma",
                    contents: [
                        {
                            name: "ALFAPRES-T",
                            model: "Pack : 5 Ml Pack",
                            modelImg: "",
                            descriptions: "Alphapres-T Eye Drops is a relatively selective alpha-2 adrenergic receptor agonist with a non-selective beta-adrenergic receptor inhibitor (topical intraocular pressure lowering agent).",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Timolol Maleate IP eqv. to Timolol......5 mg"},
                                {item: "Brimonidine Tartrate IP..............1.5 mg"},
                                {item: "Oxychloro Complex...........0.05 mg (As preservative)"},
                                {item: "Aqueous Buffered vehicle.........q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/1.png",
                        },
                        {
                            name: "A-CARTEOL EYE DROPS",
                            model: "(Carteolol Hydrochloride Ophthalmic SolutionUSP 2% w/v)",
                            modelImg: "",
                            descriptions: "A-CARTEOL Eye Drops (Carteolol Hydrochloride Ophthalmic Solution USP 2% w/v) is a nonselective beta-adrenoceptor blocking agent for ophthalmic use.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE"}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Carteolol Hydrochloride USP 20mg"},
                                {item: "Stabilized Oxychloro Complex 0.005 % w/v  (As preservative)"},
                                {item: "Sterile Aqueous  Vehicle            q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/2.webp",
                        },
                        {
                            name: "Alfapres-TH/5mL EYE DROPS",
                            model: "( Brimonidine Tartrate & Timolol Eye Drops)",
                            modelImg: "",
                            descriptions: "ALFAPRES-TH (Brimonidine Tartrate& Timolol Eye Drops) is a relatively selective alpha- 2   adrenergic agonist for ophthalmic use.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE"}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Brimonidine Tartrate IP       2 mg"},
                                {item: "Timolol Maleate IP eq. to Timolol        5 mg "},
                                {item: "Benzalkonium Chloride    Solution IP      0.005 % w/v (As preservative)"},
                                {item: " Isotonic  Aqueous  Vehicle                    q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/3.webp",
                        },
                        {
                            name: "APPACARPINE HS 2% w/v",
                            model: "(Pilocarpine Eye Drops IP 2% w/v)",
                            modelImg: "",
                            descriptions: "Appacarpine HS 2% w/v (Pilocarpine Eye Drops IP 2% w/v) belongs to a group of medicine called miotics. It is used in the treatment of glaucoma and other eye conditions. It lowers the high pressure inside the eye and helps prevent vision loss and nerve damage.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE"}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Pilocarpine Nitrate IP                        20 mg"},
                                {item: "Chlorbutol   IP                                      5mg (As preservative)"},
                                {item: "Aqueous  Vehicle                               q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/4.webp",
                        },
                        {
                            name: "BIMPOST 0.1",
                            model: "(Bimatoprost Ophthalmic Solution 0.01% w/v)",
                            modelImg: "",
                            descriptions: " BIMPOST 0.1 (Bimatoprost Ophthalmic Solution0.01%w/v) is a synthetic prostamide analog with ocular hypotensive activity. Its chemical name is (Z)-7[(1R,2R,3R,5S)-3,5-Dihydroxy-2-[(1E,3S)-3- hydroxy-5-phenyl-1-pentenyl]cyclopentyl] 5-N-ethylheptenamide, and its molecular weight is415.58. Its molecular formula is C25H37NO4.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE"}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Bimatoprost                 0.1 mg"},
                                {item: " Benzalkonium Chloride SolutionIP     0.2 mg  (As preservative)"},
                                {item: "Aqueous Buffered Vehicle               q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/5.webp",
                        },
                        {
                            name: "BIMPOST ®",
                            model: "(Bimatoprost Ophthalmic Solution 0.03% w/v)",
                            modelImg: "",
                            descriptions: "BIMPOST (Bimatoprost Ophthalmic Solution 0.03%w/v) is a synthetic prostamide analog with ocular hypotensive activity. Its chemical name is (Z)-7 [(1R,2R,3R,5S)-3,5-Dihydroxy-2- [(1E,3S)-3-hydroxy-5-phenyl-1-pentenyl]cyclopentyl] 5-N-ethylheptenamide, and its molecular weight is 415.58. Its molecular formula is C25H37NO4.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Bimatoprost                  0.3 mg"},
                                {item: "Benzalkonium Chloride Solution  USP                 0.1 mg  (As preservative)"},
                                {item: "Aqueous Buffered Vehicle                         q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/6.webp",
                        },
                        {
                            name: "BIMPOST PF-0.1",
                            model: "(Bimatoprost Ophthalmic Solution 0.01% w/v))",
                            modelImg: "",
                            descriptions: "BIMPOST PF-0.1 (Bimatoprost Ophthalmic Solution 0.01%w/v) is a synthetic prostamide analog with ocular hypotensive activity. Its chemical name is (Z)-7 [(1R,2R,3R,5S)-3,5-Dihydroxy-2- [(1E,3S)-3-hydroxy-5-phenyl-1-pentenyl]cyclopentyl] 5-N-ethylheptenamide, and its molecular weight is 415.58.Its molecular formula is C25H37NO4.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANTPRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLYAND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Bimatoprost             0.1 mg"},
                                {item: "• Aqueous Buffered Vehicle                       q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/7.webp",
                        },
                        {
                            name: "BIMPOST PF-0.3 Eye Drops",
                            model: "(Bimatoprost Ophthalmic Solution)",
                            modelImg: "",
                            descriptions: "BIMPOST PF-0.3 Bimatoprost Ophthalmic Solution) is a synthetic prostamide analog with ocular hypotensive activity. Its chemical name is (Z)-7 [(1R,2R,3R,5S)-3,5-Dihydroxy-2- [(1E,3S)-3-hydroxy-5-phenyl-1-pentenyl]cyclopentyl] 5-N-ethylheptenamide, and its molecular weight is 415.58. Its molecular formula is C25H37NO4.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Bimatoprost             0.3 mg"},
                                {item: " • Aqueous Buffered Vehicle                 q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/8.webp",
                        },
                        {
                            name: "BIMPOST - T",
                            model: "(Bimatoprost & Timolol Eye Drops)",
                            modelImg: "",
                            descriptions: "BIMPOST-T (Bimatoprost & Timolol Eye Drops) is used in the treatment of glaucoma and ocular hypertension. Bimatoprost is a prostaglandin analogue while Timolol is a beta blocker. They work by decreasing the production of aqueous humour (ﬂuid in the eye), thereby lowering the increased eye pressure. It may also be used as an eye solutionto help hypotrichosis, a condition of the eye lashes where hair growth is deﬁcient.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Bimatoprost             0.3 mg "},
                                {item: "•Timolol Maleate USP Eq. to Timolol                         5.0mg"},
                                {item: "Aqueous Buffered Vehicle                                q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/9.webp",
                        },
                        {
                            name: "BRINZO B Eye Drops",
                            model: "(Brinzolamide & Brimonidine Tartrate Ophthalmic Suspension)",
                            modelImg: "",
                            descriptions: "Brinzo-B Eye Drops(Brinzolamide & Brimonidine Tartrate Ophthalmic Suspension) is a fixed combination of a carbonic anhydrase inhibitor and an alpha 2 adrenergic receptor agonist.Brinzolamide is describedchemically as: (R)-(+)-4- Ethylamino-2-(3-methoxypropyl)-3,4dihydro-2H- thieno [3,2-e]-1,2-thiazine-6-sulfonamide-1,1- dioxide.Its empirical formulais C12H21N3O5S3",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Brinzolamide   USP   1 % w/v"},
                                {item: "Brimonidine Tartrate USP                 0.2% w/v"},
                                {item: "Stabilized Oxychloro Complex 0.005% w/v (As preservative)"},
                                {item: "Sterile Aqueous Vehicle                      q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/10.webp",
                        },
                        {
                            name: "DORZA/5mL",
                            model: "(Dorzolamide Hydrochloride Ophthalmic Solution USP 2% w/v)",
                            modelImg: "",
                            descriptions: "Dorza (Dorzolamide Hydrochloride Ophthalmic Solution USP 2% w/v) is used in the treatment of increased pressure in the eye and open-angle glaucoma, a condition which may lead to eventual vision loss.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINSIMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "DorzolamideHydrochloride IP  eq. to Dorzolamide                 20 mg"},
                                {item: "Benzalkonium Chloride SolutionIP  0.075 mg (As    Preservative)"},
                                {item: "AqueousVehicle              q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/11.webp",
                        },
                        {
                            name: "Appatim",
                            model: "Pack : 5ml Pack",
                            modelImg: "",
                            descriptions: "Appatim Eye Drops (Timolol Maleate Eye Drops) is a non-selective beta-adrenergic receptor blocking agent. Its chemical name is (-)-1-(tert-butylamino)-3-[(4-morpholino-1,2,5-thiadiazol-3-yl)oxy]-2propanol maleate (1:1) (salt). Timolol maleate possesses an asymmetric carbon atom in its structure and is provided as the levo-isomer.",
                            otherDescription: [],
                            compositionTitle: "Each ML Contains : TimololMaleate IP",
                            compositionContents: [
                                {item: "Equivalent to Timolol...........5 mg"},
                                {item: "Benzalkonium Chloride Solution IP......0.0002 Ml (As preservative)"},
                                {item: "Aqueous Buffered Vehicle............q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/12.png",
                        },
                        {
                            name: "A-Brinzo",
                            model: "Brinzolamide Ophthalmic Suspension USP 1 %w/v",
                            modelImg: "",
                            descriptions: "A-Brinzo (Brinzolamide Ophthalmic Suspension USP 1 % w/v) contains a carbonic anhydrase inhibitor formulated for multidose topical ophthalmic use. Brinzolamide is described chemically as: (R)-(+)-4-Ethylamino-2-(3-methoxypropyl)-3,4-dihydro-2H-thieno [3,2-e]-1,2-thiazine-6-sulfonamide-1,1-dioxide.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE"}
                            ],
                            compositionTitle: "Each ML Contains :",
                            compositionContents: [
                                {item: "Brinzolamide USP       10 mg"},
                                {item: "Stabilized Oxychloro Complex 0.005 % w/v (As preservative)"},
                                {item: "Aqueous Buffered Vehicle                    q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/anti-glaucoma/13.webp",
                        },
                        
                    ]
                },
                {
                    id:"2ca47d90-032b-4f3b-8803-322c7e1f4056", 
                    libelle: "Intracameral Injection",
                    contents: [
                        {
                            name: "Mydriocain PT",
                            model: "Phenylephrine, Tropicamide And Lidocaine Injection",
                            modelImg: "",
                            descriptions: "Mydriocain PT Injection is a solution which is injected into the eye. it contains three active substances: - Tropicamide which belongs to a group of medicines blocking the passage of impulses through particular nerves (known as anticholinergics), - Phenylephrine Hydrochloride Which belongs to a group of medicines mimicking the effects of impulses conveyed through particular nerves (known as alpha sympathomimetics).Lidocaine Hydrochloride which belongs to a class of drugs called amide type local anesthetics.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Phenylephrine Hydrochloride..........USP........... 0.31% w/v"},
                                {item: "Tropicamide..........USP...........0.02% w/v"},
                                {item: "Lidocaine Hydrochloride..........USP...........1% w/v"},
                                {item: "Lidocaine Hydrochloride..........USP...........1% w/"}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/intracameral-injection/1.png",
                        },
                        {
                            name: "MOXIFUR 1ml",
                            model: "Moxifloxacin Ophthalmic Solution USP",
                            modelImg: "",
                            descriptions: "Moxifur (Moxifloxacin Ophthalmic Solution USP) is a sterile 1 ml clear, pale yellow coloured, isotonic, preservative free ophthalmic solution.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLY AND RETAIN FOR FURTHER FUTURE REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Moxifloxacin Hydrochloride USP"},
                                {item: "Eq. to Moxifloxacin 0.5% w/v"},
                                {item: "Aqueous Buffered Vehicle q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/intracameral-injection/2.png",
                        }
                    ]
                },
                {
                    id:"b537602a-e6c6-4385-b443-3f6a27106e8f", 
                    libelle: "Pediatric Drops",
                    contents: [
                        {
                            name: "MYOPED PLUS Eye Drops",
                            model: "(Atropine Sulphate Ophthalmic Solution USP 0.05% w/v)",
                            modelImg: "",
                            descriptions: "Myoped-PlusEye Drops (Atropine Sulfate Ophthalmic Solution USP 0.05% w/v) is a sterile topical Ophthalmic solution. The active ingredient is represented by the Chemical Name is Benzeneacetic acid,ɑ-(hydroxymethyl), 8-methyl-8-azabicyclo- (3.2.1) Oct-3-ylester, endo-(+)-, Sulfate(2:1) (salt), monohydrate",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLYAND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Atropine Sulfate USP                   0.05%w/v"},
                                {item: "Polyhexamethylene Biguanide                0.05mg (As Preservative)"},
                                {item: "aqueous buffered vehicle                            q.s."},
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/pediatric-drops/1.webp",
                        },
                        {
                            name: "MYOPED Eye Drops",
                            model: "(Atropine Sulphate Ophthalmic Solution USP 0.01% w/v)",
                            modelImg: "",
                            descriptions: "Myoped Eye Drops (Atropine Sulphate Ophthalmic Solution USP 0.01% w/v) is a sterile topical Ophthalmic solution.",
                            otherDescription: [
                                {item: "THIS LEAFLET CONTAINS IMPORTANT PRODUCT USE AND SAFETY INFORMATION, PLEASE READ CAREFULLYAND RETAIN FOR FURTHER REFERENCE."}
                            ],
                            compositionTitle: "Each ML Contains:",
                            compositionContents: [
                                {item: "Atropine Sulphate USP                               0.1mg"},
                                {item: "Stabilized Oxychloro Complex                 0.05 mg (As Preservative)"},
                                {item: "aqueous buffered vehicle                            q.s."}
                            ],
                            childs:[],
                            features: [],                      
                            img: "/products/pharmaceuticals/pediatric-drops/2.webp",
                        },
                    ]
                },
            ]
        },
        {
            id: "devices",
            libelle: "Devices",
            icon: CpuChipIcon,
            imgBanner: "/img/products/banner/3.jpg",
            isCard: true,
            categories: [
                {
                    id:"c5c05854-90e9-4582-8f3a-044266460e37", 
                    libelle: "All devices",
                    contents: [
                        {
                            name: "SURGICAL OPERATING MICROSCOPE",
                            model: "MODEL : BRILLIANT SHIFT 230/250 (LED)",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Visualization system for Ophthalmic Micro Surgery"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            descriptionsList: [
                                {item: "The Brilliant Shift Ophthalmic Surgical Microscope is a compact and portable table mount microscope designed to provide detailed imaging of all regions of the human eye. Its precision optics provides wide field of view with extended depth of focus."},
                                {item: "Stereo Microscope with Five Step Magnification – Portable Model with LED Light Source along with Fiber Optic Cable. Foot-switch for Motorized Fine Focusing. Metallic Carrying Case (IAPB)"},
                                {item: "10X Wide Field(Optional 12.5X Wide Field)/ 10X Wide Field (Optional 12.5X Wide Field) Eye Pieces"},
                                {item: "From +6 to 6/ From +6 to 6 Diopter Adjustment"},
                                {item: "45° off vertical/ 45° off vertical Inclination to vertical   "},
                            ],
                            childs:[],
                            features: [
                                {item: "LED Illumination"},
                                {item: "Foot Pedal Controlled Fine Focus"},
                                {item: "Five Step Magnification with Crystal Clear Optics"},
                                {item: "Encoder Assisted Intensity Control"},
                                {item: "Comes in a Carrying Case"}
                            ],
                            img: "/products/devices/1.png",
                        },
                        {
                            name: "GALAXY LEO | INTELLIGENT ORBITAL PHACO",
                            model: "WITH REALTIME FLUIDICS SYSTEM",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                { item: "Galaxy LEO designed with new ultrasound technology and latest Realtime fluidics & maximum patient safety with short setup time and precise control of fluidics using a peristaltic pump with venturi effect"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/devices/5.png",
                        },
                        {
                            name: "GALAXY TURBO ORBIT",
                            model: "Turbo Orbit The Future Of Next Generation",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Most advanced LED Illuminator provides state of the art illumination for visualizing tissues very sharp"},
                                {item: "Integrated and stable IP (Infusion pressure)"},
                                {item: "In single touch foot control IP-High change over with Voice assist"},
                                {item: "Fast start up & Easy to use "},
                                {item: "Ultra Sharp Valved & Non-Valved Trocar Entry System 23G, 25G"},
                                {item: "High-Speed BI-BLADE cutter 23G, 25G"},
                                {item: "Innovative advanced technology for both vitreo retinal and cataract surgery in a single system"},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/devices/6.webp",
                        },
                        {
                            name: "Galaxy Robot",
                            model: "Entire System for ‍Posterior & Anterior ‍Surgeries",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "A Complete Vitreo Retinal"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Fast start up & user friendly"},
                                {item: "Innovative advanced technology for both vitreo retinal and cataract surgery in a single system Ultra Sharp Valved & Non-Valved Trocar Entry System 23G, 25G"},
                                {item: "Most advanced LED Illuminator provides state of the art illumination for visualizing tissues very sharp Integrated and stable IP (Infusion pressure)"},
                                {item: "High-Speed BI-BLADE cutter 23G, 25G"},
                                {item: "Toggle between regular infusion pressure to higher infusion pressure with the help of touch and footswitch control Separate Aspiration Line For Extrusion Mode"},
                                {item: "Automatic fluid air exchange (faxJ. system has facility to change fluid to air to fluid automatically with the help of touch and footswitch control"},
                                {item: "Surgeon control infusion pressure and fluid air exchange in single touch and foot operate Programmable cutting and aspiration"},
                                {item: "Longitudinal and Transverse phaco probe"},
                                {item: "Longitudinal fragmentome probe"},
                            ],                      
                            img: "/products/devices/5.png",
                        },
                        {
                            name: "Surgical Operating Microscope BRILLIANT",
                            model: "AAOM 230/250 (LED)",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Counter Balanced Spring Arms For Efficient Handling"},
                                {item: "Rotatable Filters Including UV Heat-Absorbing Filters"},
                                {item: "Two-Degree Oblique Led Illumination System With Intensity Control"},
                                {item: "Surgical Microscope With Apo-Chromatic Optics"},
                            ],                      
                            img: "/products/devices/4.webp",
                        },
                        {
                            name: "THE BRILLIANT ADVENT",
                            model: "FS - 9",
                            modelImg: "",
                            descriptions: "Precise Optics : The AAOM Brilliant Advent FS-9 has a converging binocular optical head with Precise Optics. The major advantage of this microscope is that it allows deep light penetration, provides greater visibility and better Surgical perception. Its optics offers a higher quality view with improved efficiency to doctor's working conditions.",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/devices/3.webp",
                        },
                        {
                            name: "LEGEND TRUGLOW",
                            model: "FS - 1",
                            modelImg: "",
                            descriptions: "PRECISE OPTICS: The AAMO Legend Truglow FS-1 has a converging binocular optical head with precise optics. The major advantage of this microscope is that it allows deep light penetration, provides greater visibility and better diagnostic perception. Its optics offers a higher quality view with improved efficiency to doctor's working conditions.",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/devices/2.webp",
                        },
                        {
                            name: "OPHTHALMIC EQUIPMENT STAND",
                            model: "MODEL: APPA OES-01/ ACC 003",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Simple and sleek model"},
                                {item: "Smooth and easy to use, very quiet and highly  reliable"},
                                {item: "One touch up and down positioning with dc motor"},
                                {item: "Available with one standard table top high quality  solid wood."}
                            ],                      
                            img: "/products/devices/7.png",
                        },
                        {
                            name: "Manual Surgeon Stool",
                            model: "Model : AAST",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Deluxe model pneumatic operating stool with backrest height adjustment"},
                                {item: "Ergonomic Seat foam ensures Surgeon Comfort."},
                                {item: "Net Weight: 10kgs"},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/devices/8.png",
                        },
                        {
                            name: "MOTORIZED SURGEON STOOL",
                            model: "Model : AAMST 2000",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Flexible And Robust Design"},
                                {item: "Adjustable Design Up To 660mm"},
                                {item: "Ergonomically Designed Backrest"},
                                {item: "Castor Locks For Stability Control"},
                            ],                      
                            img: "/products/devices/9.webp",
                        },
                        {
                            name: "MOBILE OPERATION TABLE",
                            model: "Model:AAMOT",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Unique And Versatile Design For High Safety And Comfort"},
                                {item: "Durable Cushion Provides Utmost Comfort To The Patient Throughout The Surgical Procedure"},
                                {item: "User Friendly Control With Footswitch And Override Panels"},
                                {item: "Quick And Easy Shifting Of Position From Chair To Operation Table"},
                                {item: "Adjustable Headrest Ensures Comfort For Doctor And Patient During Restrain"},
                                {item: "Adaptable form all"}
                            ],                      
                            img: "/products/devices/10.webp",
                        },
                        {
                            name: "Operation Table OT",
                            model: "Model:AAMOT-ss",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "AAOT has a maximum height of 920 mm and minimum height of 690mm"},
                                {item: "It is an ideal operating table with the advantages of strong applicability, easy to operate"},
                                {item: "Adjusts conveniently and steady performance."},
                                {item: "Its ergonomic design ensures both surgeon and patient comfort"},
                                {item: "Patient comfort is assured with deep antistatic cushions which are easy to clean"},
                                {item: "The adjustable cushioned head-rest ensures doctor comfort and restraint during operations"},
                                {item: "Ideal for standing or sitting working position"},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],                      
                            img: "/products/devices/11.png",
                        },
                        {
                            name: "SupraPhob BBY",
                            model: "",
                            modelImg: "",
                            descriptions: "SupraPhob BBY is a hydrophobic yellow color intraocular lens made of premium quality Biocompatible Acrylic material. Fabricated by the State of the Art of the Technology machine with Froze-tumble polish. Uncomplicated Preloaded system of SupraPhob Lens is easy and enjoyable feel to implant the Hydrophobic Acrylic Lens safer.",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "The advanced IOL design of SupraPhob BBY yields excellent stability, so that even after the shrinkage of the Capsular Bag, the IOL remains well centered"},
                                {item: "The advanced 360° Square Edge technology makes SupraPhob BBY Lens PCO free"},
                                {item: "The UV blocking Chromophores of SupraPhob BBY protects the retina from UV lights"},
                                {item: "Each Supraphob BBY is individually tested for high MTF which increases the accuracy & the quality of every IOL to its best."},
                            ],                      
                            img: "/products/devices/12.webp",
                        },
                        {
                            name: "PMMA Lens",
                            model: "APPALENS 101",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Since 1949 Intraocular lenses have been made of PMMA, due to its lightness, clarity and stability. Even today, PMMA is the material of choice. PMMA offers outstanding stability and optical quality for a predictable outcome and maximum performance. Millions of odd eyes have been implanted with our basic and premium range of PMMA lenses with excellent outcome. Thanks to the inherent quality of the material."}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/13.png",
                        },
                        {
                            name: "AMOGH SMART",
                            model: "OPHTHALMIC GREEN LASER SYSTEM-532 NM",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Single Spot Technology : The Amogh Smart is the green laser photo coagulator system designed with precision to treat retinal disorders using a single spot technology. Its effective touch screen interface allows easy access to the entire system providing efficient treatment to the patients."},
                                {item: "Unique Joystick : Electronic micro manipulator on the joystick ensures the ultimate in controlled safety. The motorized joystick provides an ease of use to the surgeon allowing an increased level of comfort during treatment."},
                                {item: "Magnificent Operative Design : The Amogh Smart provides an additional advantage to the users by providing LED illumination in the fiber optic cable connecting port, foot pedal & ON/OFF switch for their easy identification in the dark room. It also has an integrated voice confirmation technology providing better guidance to the user."},
                                {item: " Laser Indirect Ophthalmoscope (Optional Delivery System): Amogh Smart is designed with LIO compatibility which is ideal for patients who are best examined and can be treated in supine position, including neonates, small children and disabled patients. The major advantage of this is it has a variable focus option which provides improved diagnosis."},
                                {item: "Endoprobes For Ophthalmic Endophotocoagulation (Optional Delivery System): These disposable probes have prefect ergonomic design, straight & curved/angled tip which are pre-sterilized. It provides an easy entry through small gauge cannulas and its tapered curve provides excellent laser energy delivery."}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Vertical movement adjustment for illumination beam"},
                                {item: "Ultra view inbuilt eye safety laser filters provides a crystal clear view of developing lesions"},
                                {item: "Coaxial system of laser aiming beam along with white light illumination gives comfortable treatment"},
                                {item: "LIO can be made compatible for other selected lasers"},
                                {item: "True focus optical system"},
                                {item: "Small pupil adjustment"},
                            ],   
                            img: "/products/devices/14.webp",
                        },
                        {
                            name: "JERICHO",
                            model: "OPHTHALMIC GREEN LASER SYSTEM-532nm",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Maximum Efficiency: Jericho Is Designed With Effective Touch Screen Interface Along With Instant And Easy Access To The Entire System Providing Superior Surgical Treatment To Retinal Problems. It Is Versatile In Performance Delivering More Convenient And Efficient Treatment To The Patients."},
                                {item: "Advance Integrated System: Advanced Optics In Integrated Laser Slit Lamp And The Electronic Micromanipulator In It Allows User To Move The Illumination Altogether With Coaxial Aiming Beam. This Device Is Designed With Perfect Ergonomics Providing Great Comfort To The User."},
                                {item: "Multispot Technology: The Multispot Green Laser Photo Coagulator System Is Designed To Treat Retinal Disorder Using A Single Spot And Also With Different Predetermined Patterns. It Has A Wide Range Of Pulse Duration From 10ms- 50ms For All Available Patterns"},
                                {item: "Magnificent Operative Design: The Jericho Provides Additional Advantage To The Users By Providing LED Illumination To The Fiber Optic Cable Connecting Port, Foot Pedal & ON/OFF Switch For Their Easy Location In A Dark Room. It Also Has A Dedicated Motorized Table Delivering More Comfort To The Patient."},
                                {item: "Safety Filters: True Color And Permanently Inbuilt Safety Filters Enable Comfortable Physician’s View And Eliminates Color Distortion."},
                                {item: "Integrated Design: Jericho Has Been Made Compatible For All Three Delivery Systems Namely Integrated Laser Slit Lamp (Multispot), Laser Indirect Ophthalmoscope Endo Probes Which Makes This Product User Friendly."}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Less Collateral Damage To Surrounding Tissue"},
                                {item: "Less Heat Diffusion To The Retina & Choroid"},
                                {item: "Less Heat Diffusion To The Retina & Choroid"},
                                {item: "Comfortable Treatment Better Tolerated By Patients"},
                                {item: "Shorter Laser Sessions Compared To Classic Treatment"},
                                {item: "Extremely Fast Treatment"},
                            ],   
                            img: "/products/devices/15.webp",
                        },
                        {
                            name: "AmOGH  SmART  810 Nm",
                            model: "INFRARED LASER 810nm",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Light weight and portable"},
                                {item: "LED illuminated foot switch"},
                                {item: "LCD touch Screen"},
                                {item: "Voice prompting for user guidance"},
                                {item: "Various user Programmable printouts format"},
                                {item: "Programmable presets and retrieval"},
                                {item: "Volume control for voice and buzzer"},
                            ],   
                            img: "/products/devices/16.webp",
                        },
                        {
                            name: "LASER INDIRECT OPHTHALMOSCOPE ",
                            model: "400 Μ On The Retina 350 Mm",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Ideal for patients who are best examined and can betreated insupine position, including neonates, small children and disabled patients"},
                                {item: "Variable focus option is an added advantage"},
                                {item: "LCD touch Screen"},
                                {item: "Vertical movementadjustment for illuminationbeam"},
                                {item: "Ultra view inbuilt eye safety laser filters provides a crystal clear view of developing lesions"},
                                {item: "Coaxial system of laser aiming beam along with white light illumination gives comfortable treatment"},
                                {item: "LIO can be made compatible for selected lasers"},
                                {item: "True focus optical system"},
                                {item: "Small pupil adjustment"}
                            ],   
                            img: "/products/devices/17.png",
                        },
                        {
                            name: "Optical Coherence Tomography ",
                            model: "Model: APPASAMY CIANA",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "AN ADVANCED HIGH SPEED HD OCT FOR THE COMPLETE RETINAL IMAGING & ANALYSIS"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "A simple OCT to cater the need of every Ophthalmologist"},
                                {item: "High quality images obtained with simple user friendly interface"},
                                {item: "Accurate measurement of RNFL and the Optic Nerve head Analysis for Glaucoma"},
                                {item: "Simple control software for Image acquisition, Processing and Patient database storag"},
                                {item: "High average sum processing acquired OCT images to HD image quality"},
                                {item: "Anterior Segment OCT image by additional Optical adaptor provided"},
                            ],   
                            img: "/products/devices/18.webp",
                        },
                        {
                            name: "CL-UVR RAPID",
                            model: "CORNEAL CROSS LINKING",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Efficient Treatment: Motorized fine focus for ideal working distance that does not harm the endothelial cells and renders the treatment effectively. Aiming Beam Laser for accurate focusing and positioning provides unparalleled beam profile for treatment efficiency and safety. "},
                                {item: "User Friendly Interface : Designed with user adjustable power from 1.00 mW to 30.00 mW and energy setting upto 5.4 J/cm2. It has a default user programmable memory ( U1 & U2). Its Built in protocols maintain the same total energy of 5.4 J/cm2."},
                                {item: "Robust Base Assembly: Rigid mobile floor stand provides enhanced mobility with castor lock that can withstand the solid counterweight Convenience in adjusting heights."},
                                {item: "Bringing Features Together For Successful Treatment: Clustering Illumination system with dual aiming beam to achieve the effective homogeneous radiation of UV beam. Precise adjustable arms for convenient handling."},
                                {item: "Video Screen Assistance: Built in video camera for Enhanced treatment safety. Assisting doctors to have control over the treatment providing adequate visualization through LCD screen."},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "CL - UVR RAPID CORNEAL CROSS LINKING : The New Cross-Linking Machine Redefines Very Fast Treatment Approach. The Rapid CL-UVR System Offer More Homogeneous Beam And An Adjustable Energy Level To Allow Very Good Results."},
                                {item: "ULTRA EFFICIENT LED CLUSTER SYSTEM: Capable Of Performing The Traditional And Rapid Cross Linking Treatment As Fast As 3 Minutes. It Provides Variable Spot Size For Different Corneal Diameter. Its Built In Video Camera Provides Enhanced Treatment Safety."},
                            ],   
                            img: "/products/devices/19.webp",
                        },
                        {
                            name: "YAG & SLT COMBO",
                            model: "MODEL : 507",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Simple And Compact Design: The YAG & SLT combo 507 laser integrates the YAG & SLT laser with diagnostic slit lamp in a single sophisticated system providing a simple and compact design. Its affordability and the long term performance along with easy switching between YAG and SLT modes are considered as an advantage for the user to provide optimal treatment to the patients."},
                                {item: "Optimized Viewing: The FDA approved AIA12 SLIT LAMP is integrated with the YAG & SLT combo 507 for the laser output delivery. Its powerful LED light source provides bright light ensuring extended depth of focus and sharp field of view for optimized viewing of posterior and anterior segments."},
                                {item: "Powerful Yag Mode: The YAG mode has a super Gaussian beam profile which allows you to perform capsulotomy and iridotomy procedures with a lower energy level and higher productivity for the accurate delivery of laser thus avoiding the risk of lens pitting."},
                                {item: "Safe Slt Mode: The “Selective Laser Trabeculoplasty” is a laser procedure used to treat open angle glaucoma by reducing the pressure in the eye. It uses the short pulse with low energy to target the melanin rich cells which absorbs the laser light stimulating the body’s own healing response to restore the trabecular meshwork function thus reducing eye pressure. As this mode ensures selective delivery with no permanent damage this is used as an effective repeat therapy."},
                                {item: "Precise Aiming Beam: Smart Control panel with multiple functions adding comfort to the Ophthalmologist or Optometrist for ease of use."},
                                {item: "Optimal Power Supply: The YAG & SLT combo 507 uses xenon flash lamps as the power source.  The power is generated through the flashes emitted from Xenon flash lamps, although each flash has a short duration a flash lamp can be accurately controlled to operate in a high hertz mode to generate the intense pulsed light providing an un-interrupted supply."},
                                {item: "Error Codes: The YAG & SLT combo 507 is designed with a unique feature which provides error codes to the user for the product breakdown identification."},
                                {item: "Short Treatment Time: The YAG & SLT combo 507 is engineered with the fastest pulse repetition rate at 2.5Hz, which allows the surgeon the perform the YAG and SLT procedures quickly and accurately thus reducing the treatment time rapidly."},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/20.webp",
                        },
                        {
                            name: "Appa Yag Laser",
                            model: "Model : 307",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Ergonomic design with unmatched precision and power"},
                                {item: "Affordable and compact with long term working performance"},
                                {item: "5-step focus shift provides less lens pitting for posterior and anterior"},
                                {item: "AIA-12 Slit Lamp 5 step head with 7 degree convergent for customer convenient and clear focus"},
                                {item: "Super Gaussian mode affords lower energy during capsulotomy and iridotomy"},
                                {item: "Variable Aiming Beam Intensity for user convenient"},
                                {item: "Q-switched mode of operation and CQ crystal technology for stabilized output in every shot"},
                                {item: "Error code provides product breakdown identification to the users"},
                                {item: "Attenuator for linear energy variations"},
                                {item: "CE marked and FDA approved AIA-12 Slit Lamp"},
                                {item: "More than 5000 Lasers have been working worldwid"},
                            ],   
                            img: "/products/devices/21.webp",
                        },
                        {
                            name: "Ophthalmic Ultrasound Scanner",
                            model: "Model : Appascan Max",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Auto / Manual Gain"},
                                {item: "Automatic calculation of standard deviation and average axial length (series of 10 measurements)"},
                                {item: "Simultaneous display of 3 different IOL calculations"},
                                {item: "RTC shows the Date and Time throughout the life of the instrument."},
                                {item: "Various user Programmable printouts Format"},
                                {item: "Measurement possible for Various Velocity of each segment."},
                                {item: "AME Formula - The new generation programmed software takes care of any Axial Length."},
                            ],   
                            img: "/products/devices/22.webp",
                        },
                        {
                            name: "OPHTHALMIC ULTRASOUND SCANNER",
                            model: "MARVEL II ‍AB-SCAN WITH UBM",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Good Image Quality: With the latest probe technology MARVEL II AB scan with UBM produces high resolution image for accurate diagnosis. There are 3 ranges of probes are available for B-scan"},
                                {item: "B-Scan Modes : There are 3 basic B-Scan modes provided by the equipment which enhances the diagnosis in the targeted area. The following are the types of modes for specific applications"},
                                {item: "High Quality Image Recording: The MARVEL II AB scan with UBM  allows recording 600 frames per cine(Total 600* 100 =6000 frames) by providing motion picture diagnosis."},
                                {item: "Improved Recording System: This device provides high quality video recording function. The major advantage of this feature is that frame by frame image can be captured from the video recorded and gain mode application can be used for the corresponding frames."},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "A SCAN: Enhanced Diagnosis: The MARVEL II AB scan with UBM uses the conventional A-scan pattern recognition program for the axial length measurement. It utilizes ultrasound waves of 10MHz frequency for the diagnosis. The built-in fixation LED for patient Target of Accuracy Reading."},
                                {item: "A SCAN: Iol Calculator: The IOL calculator is easy to use and includes all formulas, including SRK – T, SRK – II, Holladay, Binkhorst, ( Hoffer Q : Price on Request ), Haigis, and Post Refraction Formulae. This system provides quick and accurate calculations."},
                                {item: "B SCAN: High Resolution Imaging Technology : The MARVEL II AB scan with UBM produces reliable and completely focused B-mode image without any degradation of resolution. It has a unique technology which eliminates artifacts and allows deep penetration without any impact in image quality. It has a high resolution display system for effective diagnosis."},
                            ],   
                            img: "/products/devices/23.png",
                        },
                        {
                            name: "Ophthalmic Ultrasound Scanner",
                            model: "Model : Appascan Max With Pachymeter",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Easily Adjust IOP based on measured CCT."},
                                {item: "CCT measurements"},
                                {item: "Guaranteed fast and easy Measurements"},
                                {item: "Continuous/Mapping"},
                                {item: "User programmable Mapping (up to 10 maps)"},
                                {item: "Adjustable Corneal Velocity"},
                                {item: "Automatic Sensing Algorithm"},
                                {item: "Measurement Review"},
                                {item: "100 Scan Average with Standard Deviation"},
                                {item: "20 MHz Angled or Straight probe"},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Auto / Manual Gain"},
                                {item: "Automatic calculation of standard deviation and average axial length (serial of 10 measurements)"},
                                {item: "Simultaneous display of 3 different IOL calculation"},
                                {item: "RTC shown the Date Time throughout the life of the instrument."},
                                {item: "Various user Programmable printouts format"},
                                {item: "Measurement possible for various velocity of each segment."},
                                {item: "AME Formula - The new generation programmed software takes care of any Axial Length."}
                            ],   
                            img: "/products/devices/24.webp",
                        },
                        {
                            name: "Ophthalmic Ultrasound Scanner",
                            model: "Model : Appascan Max P",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Adjustable Corneal Velocity"},
                                {item: "Automatic Sensing Algorithm"},
                                {item: "Measurement Review"},
                                {item: "100 Scan Average With Standard Deviation∙ "},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/25.png",
                        },
                        {
                            name: "DynamiQ Slit Lamp",
                            model: "Imaging system merges high-quality optics",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Software Supports Live motion and Live recording"},
                                {item: "Inbuilt storage 240 GB and 8 Gb Ram"},
                                {item: "Slit lamp Illumination to be controlled through Slit lamp Base Regulator"},
                                {item: "Inbuilt Wi-Fi and Bluetooth for file sharing"},
                                {item: "Software Supports Image Comparison and print out."},
                                {item: "Diffuser"},
                                {item: "External Light Source"},
                                {item: "Triggering Switch in Joystick to capture image"},
                                {item: "User account management (Ex: Doctor , Assistant, administrator)"},
                                {item: "Library"},
                                {item: "Online feedback"},
                                {item: "Supports USB3.0 for High speed data Transmission"},
                            ],   
                            img: "/products/devices/26.webp",
                        },
                        {
                            name: "Auto Ref-Keratometer",
                            model: "AARK-1M",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Advanced Display System: Unique touch screen display allows the user to perform the procedure more conveniently and offers excellent visibility. The 7-inch color LCD screen with tilting function offers easy operation for the operator during the seating and standing position. The user interface has fine detail & quality."},
                                {item: "Enhanced Joystick Performance: AARK-IM uses a multidirectional joystick which provides extremely smooth operation without any strain on hand. The control key feature with a new easy-to-press design that provides a greater level of comfort for user virtually"},
                                {item: "Smart Data Transfer: The smart Bluetooth facility enables AARK-1M transfer measured data to any external Bluetooth enabled device like mobile phone, personal computers & other medical devices etc."},
                                {item: "One Touch Locking Mechanism: The user can fasten the main body by advanced one-touch lock .The locking mechanism will ensure the firm locking of the main body. "},
                                {item: "IOL Mode: After redesigning the whole system and making the improvement, even the cataract and IOL implanted patient can be measured."},
                                {item: "Retro Illumination & Anterior Imaging: This is a very special feature which allows the user to take photographs of eyes with cataract. This helps in counseling the patient for further procedures."},
                                {item: "Refractometry & Keratometry Measurement: AARK-1M provides an extensive dioptric measurement range from -25D to +22D for refractometry in steps of 0.12 and .25D.The radius of curvature for keratometry is from 0.5 ,0.12 and 0.25D.This operation allows the user to obtain results immediately and conveniently."},
                                {item: "CLBC(Contact Lens Base Curve): Base curve of hard contact lens can be measured using contact lens holder."},
                                {item: "Efortless Measurement: Measurement can be taken easily by moving the two cursors on the display to the boundary of cornea or pupil.This is useful for deciding the diameter of a contact lens and for other contact lens fittings."},
                                {item: "Motorized Chinrest: Motorized chinrest allows the operator to align the examinee eye with the eye mark up/down button which is placed on the lower base of the body"},
                                {item: "Faster Printouts: By simple touch print icon, the results can be obtained immediately after the measurement is taken with the in-built printer"},
                                {item: "Color Fixation Targets: A scenic color photo target will allow the examinee to be in a relaxed state, thereby making the eye fixation easier for both children and patient with difficult eyes."}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/27.png",
                        },
                        {
                            name: "LCD Digital Visual Acuity Chart",
                            model: "I CHART HD SMART",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Advanced Display System: The I chart hd smart uses 22 inches high resolution led back light display. Its advanced display system provides optimal visualization and accurate results to the user."},
                                {item: "Contrast Ratio: The I chart hd smart allows the users the control the contrast ratio as per the patient needs."},
                                {item: "Testing Various Distances: Wide range of optotypes and tests are available. Among all the optotypes the followings are used frequently"},
                                {item: "Lea Numbers: The lea numbers were designed by lea hyvärinen which consists of five numbers 5, 6, 9, 8, 3, 2, 0 of the same size of snellen letters. The lea numbers can be used for people who are unfamiliar with english alphabets."},
                                {item: "English: This option displays any optotype from the english language."},
                                {item: "Tumbling E: In 1976 taylor created a chart using a single optotype, a stylized letter e, in various orientations to test visual acuity of australian aborigines. This has become standard for testing of illiterates and populations not familiar with the roman alphabet."},
                                {item: "Pediatric: We use pediatric chat for testing pediatric patients. The picture tests are used in the assessment of children's visual acuity. The commonly recognizable pictures like chocolate, shirt, ice cream, and cup were included in it."},
                                {item: "The Snellen Eye Chart: Eye doctors have utilized the snellen eye chart since its development in 1862. Traditionally, this eye chart contains eleven lines of block letters. The first line of the snellen eye chart simply has the large capital “e” at the top. Each additional row contains more letters, but the letters are smaller than those found in the previous row. In order to complete this test, the patient must cover one eye. He or she then reads the letters out loud from each row, starting with the top row."},
                                {item: "Etdrs: ETDRS stands for early treatment diabetic retinopathy study. The etdrs test is designed to eliminate inaccuracies in the snellen and sloan tests. Etdrs acuity testing has become the worldwide standard for visual acuity testing, replacing the snellen and sloan acuity tests."},
                                {item: "Language Types: High resolution test images are available in 14 different languages including english."},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/28.png",
                        },
                        {
                            name: "LED Slit Lamps ",
                            model: "MODEL : AIA-12 2S-L | AIA-12 3S-L | AIA-12 5S-L | AIA-12 ZOOM-L",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Optimal Examination: Compact slit lamp with high optical performance offering provides optimum visualization for the user. Converging binocular optics with parallel drum magnifications changer that allows for quick selection among five convenient 6x, 10x, 16x25x and 40x and a level type for 2 step slit lamp. The user’s field of view is maximized for clear and sharp vision of ocular defects at the periphery."},
                                {item: "Multiple Magnification Slit Lamp: Optional color filters to obtain superior fluorescein images. Color filters include cobalt blue, green, red free, heat absorbing."},
                                {item: "Exceptional Illuminance: Led light source make examination very comfortable with an optimal color temperature and exceptional performance. Shaper & uniform distribution of light is achieved in led."},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/29.png",
                        },
                        {
                            name: "Glaufield Lite® | APPA AUTO PERIMETER",
                            model: "AP901 CTS",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Compact Design: The Glaufield Lite auto perimeter is designed with perfect ergonomics and compact size which is ideal for space constrained environment. Due to its compact design it can be flexibly positioned in any room according to the user needs."},
                                {item: "GHT Analysis: Glaucoma Hemifield Test is an algorithm to conclude the Entire Interpretation and to imply the depth of defect in Single Line."},
                                {item: "Flicker Perimetry: The Glaufield Flicker Perimetry function can be combined with any standard test, increasing the flexibility for use in private practice, hospital clinic or research."},
                                {item: "Testing Patterns: The Appa Perimeters have a wide range of tests available to suit every need – Glaucoma, Full Field, Central 10, Central 30, Vertical Meridian, Ptosis, Peripheral, Macula, Driving Test and others. "},
                                {item: "Test Reliability Aspect: Fixation losses, False Positives & False Negatives Fixation can be maintained by HK (blind spot) or Mosaic Attention Monitor Methods."},
                                {item: "Analysis Software: The field Explorer Version 4.0 software has been designed to be spontaneous and simple to use, even for operators with limited experience. The interactive menus are easy to use and provide extensive information reducing the time spent preparing reviewing and printing patient exams."},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/30.png",
                        },
                        {
                            name: "HAND HELD SLITLAMP",
                            model: "Model : PSLAIA-11",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Simple And Compact Design: Since it is small and light weight, it can be used easily in the case of a medical care service at home. You can use for the medical care of the eye of small animal etc. "},
                                {item: "Multicoat Optics For Superior Visualization"},
                                {item: "10X Eye Piece & Magnification"},
                                {item: "Optimum Bright LED For Homogeneous Visualization: The luminance of about 10,000 lux was secured by adoption of the high luminance white LED for the source of light, and the life of the light become long. The life of the LED is 50,000 hours or more... "},
                                {item: "Multiple Color Filters: Selectable of the various filters such as the Cobalt Blue, Green and Conversion Filters for colour temperature. "},
                                {item: "Efficient & Rechargeable: Continuous operation is about 2hours possible with a battery (in full charged state with maximum illumination). The operating time changes with amount of illumination and remaining battery level."},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/31.webp",
                        },
                        {
                            name: "Goldman Applanation Tonometer",
                            model: "Tonometer AATM 5001",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "To Measure Intraocular Pressure"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/32.png",
                        },
                        {
                            name: "OPHTHALMOSCOPE | BINOCULAR INDIRECT",
                            model: "Model : AIO-7",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Light-Weight And Compact: The AAIO Binocular indirect ophthalmoscope is engineered with an advanced light-weight material leading to a smaller and more compact instrument. It is combined with a soft cushioned and vibration free head band providing exceptional comfort to the user especially during long examination."},
                                {item: "Long Battery Life: The Binocular indirect ophthalmoscope is designed with rechargeable battery, this allows continuous usage of the instrument upto to 5hrs without any interruption. It also provides more than 1000 charging cycles thus increasing the product efficiency effectively. "},
                                {item: "Flexible Optics"},
                                {item: "The high-contrast optics combined with LED light source offersÂ sharper and clearerÂ high-definition retinal images. The AAIO has an optics that can be tipped and locked at various positions providing maximum flexibility to the user."},
                                {item: "Filters: The AAIO Binocular indirect ophthalmoscope has in-built multiple color filters and adjustable apertures in the optics to avoid harmful radiation. This device is free from UV or IR radiation as the LED light source emits energy in the range of 400-700 nm. Color Filters include Cobalt Blue and Green (Red Free)"},
                                {item: "Efficient Workflow: The main purpose of the AAIO binocular indirect ophthalmoscope is to provide efficient diagnosis. Since it is wireless it ensures easy viewing, providing flexible workflow."}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/33.webp",
                        },
                        {
                            name: "Fundus Camera",
                            model: "Model : AAFFA 01",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Retinal Imaging System"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Colour Fundus with FFA images obtained at a high resolution Digital Camera"},
                                {item: "High sensitivity CMOS image sensor for superior image quality ."},
                                {item: "User friendly alignment and focus by split line and working distance dots"},
                                {item: "Simple control software for Image acquisition, Processing and Patient database storage"},
                                {item: "Avoids Patient's eye dilation time since it uses nonmydriatic technology"},
                                {item: "100% Patient co-operation obtained during image capture because of Patient friendly IR illuminating source"},
                            ],   
                            img: "/products/devices/34.webp",
                        },
                        {
                            name: "Refractor Head - Manual",
                            model: "Model : AA 201",
                            modelImg: "",
                            descriptions: "Subjective type Refraction Testing Equipment manual Refactor with High Quality Lenses. Smooth, accurate measurement of Spherical and Cylindrical features, superb range of Auxiliary Lenses  Unique Cross Cylinder Configurations, Convergence System allows Optimal patient Examination  Both side of the lenses are Anti-Reflection Coated",
                            otherDescription: [
                                {item: "MEASUREMENT OF SPHERICAL AND CYLINDRICAL FEATURES"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/35.png",
                        },
                        {
                            name: "Ophthalmic Lensometer",
                            model: "Model : KMS 14",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Measure the prism of a Lens"}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Accurate measurements with external power reading"},
                                {item: "Dual power supply system"},
                                {item: "Clear green illumination target"},
                                {item: "Energy-efficient LED illumination"},
                                {item: "Possibility of accurate centering and marking of lenses"},
                            ],   
                            img: "/products/devices/36.webp",
                        },
                        {
                            name: "REFRACTION UNIT",
                            model: "AARU 2000 | AARU 2000 ELEGANT | AARU 2002",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Elegant Design: The Ophthalmic Refraction Unit has upholstered top with soft cushioned seat and back rest providing elegant design to the unit. It has a comfortable arm rests and a tip-up foot rest that moves up and out of the way for patient convenience and comfort during examination. "},
                                {item: "Adjustable Chair Unit: Adjustments of the Ophthalmic Chair are motorized. The Ophthalmic Chair height is adjustable and easily function able with the help of smart console unit & foot switch."},
                                {item: "Robust Support: The chair unit is provided with strong arm pole to support lamp, phoropter, chart projector, near Vision holder & Indirect Ophthalmoscope. This also has comfortable arm rests, convenient instrument holder tray and sliding tabletop for accommodating two equipments simultaneously."},
                                {item: "Maximum Efficiency: Unique integrated design with most favorable addition facility for maximum efficiency in very limited space providing intelligent space management."},
                                {item: "Control Panel: Smart Control panel with multiple functions adding comfort to the Ophthalmologist or Optometrist for ease of use. "},
                                {item: "Sliding Table: Solid & well balanced Sliding Table with an ample space that can withstand maximum of two OPD equipment weighing close to 30 Kg. "},
                                {item: "Footswitch: Addition option of Footswitch control making the user to operate chair UP & DOWN function conveniently."},
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/37.webp",
                        },
                        {
                            name: "PHOTOGRAPHY & DOCUMENTATION SIMPLIFIED",
                            model: "MODEL : SL-CM ICM-7",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [
                                {item: "Precisely Made Optics For Detailed View: High field of view and depth of focus with adjustable eyepiece enhancing comfort to the doctor even during the longer examination. "},
                                {item: "Enlarging Image For Accurate Diagnosis: 5 step manual drum magnification changer, providing wide range of magnification from 6x to 40x. "},
                                {item: "Adopted To The Fact - Led Illumination: Consciously selected LED for its incredible performance. Universally preferred light source with a color temperature of 3800K, that works best when combined with any color filters. "},
                                {item: "Diffuser For Creating Soft Dramatic Effects On Led Illumination: "},
                                {item: "Easily accessible diffuser to diffuse the Illumination, that gives an exceptional overview of the anterior segment."},
                                {item: "Colors Filters For Superior Vision: Varied option of color filters like Green, Cobalt blue, Yellow, Neutral density , Heat absorbing filter. Tear / Scar or even minute structure can be obtained using the filters."},
                                {item: "Aperture Control: Aperture control with Multiple diameter."},
                                {item: "Design & Technology At Its Best: UHD Digital SL CAM integrated with seamless design Slit lamp offers a high-resolution image that could be easily captured by smart capturing trigger option. "},
                                {item: "Solid Integrated Base Assembly Enabling Ease Of Operation: Elegantly mounted trigger button on the omni style joystick with a convenient reach to illumination intensity knobs for effortless Image or Video. "},
                                {item: "Robust Motorized Instrument Table: Simple in design mechanical table with castor lock to lock the movement of the table incase of uneven floor and silent motor with Up and Down range movement of 200 mm. "},
                                {item: "MIPS Software (Medical Image Processing System): Simple in design mechanical table with castor lock to lock the movement of the table incase of uneven floor and silent motor with Up and Down range movement of 200 mm."},
                                {item: "Simple Window For Image & Video Capturing: Innovative camera supports the software to capture image or video in real time in one trigger. Progressive application with varied options of modes with pre-defined setting. "},
                                {item: "Tools For Editing Image: Option of editing the captured image with inbuilt tools. "},
                                {item: "Patient History: Retrieving of particular patient history is possible in one single click."},
                                {item: "Patient Information Management: Software allows you to filter or find patient details, based on the symptoms, personal data & Diagnosed information. "},
                                {item: "Online Feedback: Facilitates an Ophthalmologist / Optometrist to give complaint or feedback through online, where in the manufacturer will resolve the issue based on the input received."}
                            ],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [],   
                            img: "/products/devices/38.webp",
                        },
                        {
                            name: "VIDEO INDIRECT OPHTHALMOSCOPE",
                            model: "Model: AAVIO -7",
                            modelImg: "",
                            descriptions: "NEW high resolution Camera. Capable of capturing high-resolution videos and still shots of examinations. No disturbing picture noise due to increased light sensitivity of the camera and also suitable for premature babies examinations. Ease to share the examiner's view to educating patients on the diagnosis, recording techniques for presentations, and patient's records.",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "High Resolution Full HD camera 1920 x 1080p, TE cooled, with IR cut filter, broadcast quality,Real-Time High Resolution Video Capture"},
                                {item: "Telemedicine based screening of infants - ROP"},
                                {item: "The video and images stored in SSD 240GB"},
                                {item: "Instant Play back and Continuous video play"},
                                {item: "Snap shot of single picture enables us to save PDF"},
                                {item: "Wired Foot switch for freezing an image and taking snap shots"},
                                {item: "Simultaneous viewing on monitor plus video recording"},
                                {item: "Large Visual Field angle - Field Angle : 450"},
                                {item: "Stereoscopic Binocular view"},
                                {item: "High transmission optics reduces reflections and excellent Fundus observation Small Pupil Adaptability"},
                                {item: "Contract Color filters, Wide angle soft light diffuser (Grey, RED free, Blue, Heat Absorption)"},
                                {item: "High brightness variable LED Illumination, the unit enables an operator to view from the posterior pole to the equator as a single image"},
                                {item: "100% dust proof optics"},
                                {item: "Capture exactly what you see through the Oculars in real time wide angle soft light diffuser"},
                            ],   
                            img: "/products/devices/39.png",
                        },
                        {
                            name: "KERATOMETER",
                            model: "MODEL : KMS-6",
                            modelImg: "",
                            descriptions: "",
                            otherDescription: [],
                            compositionTitle: "",
                            compositionContents: [],
                            childs:[],
                            features: [
                                {item: "Coincidence Focusing System With Positive Measurement"},
                                {item: "LED Light Source"},
                                {item: "One Position Instrument Measures Both Meridians"},
                                {item: "Two Way Adjustable Chin And Head Rests"},
                                {item: "Positive Fixation Permits Rapid Measurement Of Central Cornel Thickness"},
                                {item: "Adjustable Eye Piece Achromatic Lenses"},
                                {item: "Dual Eye Level Sighting System Facilitates Horizontal Alignment"},
                                {item: ""},
                            ],   
                            img: "/products/devices/40.png",
                        },
                    ]
                },
            ]
        }
    ]
}

export default ProductItems
