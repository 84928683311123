import React from 'react'
import ImgBanner from "../assets/img/news/6.jpg";
import { PagesBanner } from '../components/partials'
import NewsAndUpdateElement from '../components/Elements/NewsAndUpdate/NewsAndUpdateElement';

const NewAndUpdate = () => {
  return (
    <>
        <PagesBanner img={ImgBanner} title="News & update" />
        <NewsAndUpdateElement/>
    </>
  )
}

export default NewAndUpdate