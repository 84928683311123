import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import ProductItems from '../components/Elements/Products/ProductItems';
import ProductContent from '../components/Elements/Products/ProductContent';
import { PagesBanner } from '../components/partials';
import { ArrowLongRightIcon, ArrowRightCircleIcon } from '@heroicons/react/24/outline';
import { StopIcon } from '@heroicons/react/24/solid';
import { Popover } from 'flowbite-react';

const ProductDetail = () => {
    const { productId, categoryId } = useParams();

    const [element, setElement] = useState([]);
    const [categoryDetail, setCatgoryDetail] = useState([]);

    useEffect(() => {
        const elements = ProductItems.products?.filter(element => (element.id === productId));
        setElement(elements[0]);
        
        const category = elements[0].categories?.filter(category => (category.id === categoryId));
        setCatgoryDetail(category[0]);

    }, [productId, categoryId])

    const contents = (product, img) => (
        <div className="w-full text-sm text-gray-500 ">
            <div className="grid grid-cols-5">
              <div className="col-span-5 p-3">
                <div className="space-y-2 max-h-[80vh] pr-1 sm:max-h-[60vh] overflow-x-hidden overflow-y-auto">
                    <div className="grid grid-cols-1  sm:grid-cols-2 gap-1 w-full">
                        <div className="order-2">
                            <p className="text-1xl font-medium"> { product.name } </p>
                            <p className="text-md font-light"> { product.model } </p>
                            { product.otherDescription?.lenght !== 0 &&
                                <ul className="my-2 flex flex-col gap-1">
                                    { product.otherDescription?.map((description) =>(
                                        <li className="flex items-start gap-1 "> 
                                            <div className="mt-[5px]"><StopIcon className="w-[7px] h-[7px]"/></div> 
                                            <div>{ description.item }</div>
                                        </li>
                                    ))}
                                </ul>
                            }
                            {((product?.descriptions) && (product.descriptions !== "")) && <p className="text-md md:text-xl font-bold">Descriptions :</p> }
                            
                            <p className="text-md text-justify mb-2">
                                { product.descriptions } 
                            </p>
                            {((product?.descriptionsList) && (product.descriptionsList?.lenght !== 0)) && <p className="text-md md:text-xl font-bold">Product Descriptions :</p> }
                            
                            {
                                product.descriptionsList?.lenght !== 0 && 
                                <ul className="my-2 flex flex-col gap-1">
                                    { product.descriptionsList?.map((description) =>(
                                        <li className="flex items-start gap-1 "> 
                                            <div className="mt-[5px]"><StopIcon className="w-[7px] h-[7px]"/></div> 
                                            <div>{ description.item }</div>
                                        </li>
                                    ))}
                                </ul>
                            }

                            
                            
                            { product.features?.lenght > 0 && <p className="text-md md:text-xl font-bold">Product features :</p>}

                            {
                                product.features?.lenght !== 0 && 
                                <>  
                                    <ul className="my-2 flex flex-col gap-1">
                                        { product.features?.map((feature) =>(
                                            <li className="flex gap-1 "> 
                                                <div className="mt-[5px]"><StopIcon className="w-[7px] h-[7px]"/></div> 
                                                <div>{ feature.item }</div>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            }
                             
                            {product.compositionTitle !== "" && <p className="text-md md:text-xl mt-2 font-bold">Composition :</p>}
                            <p className="text-md font-medium">{ product.compositionTitle }</p>
                            <ul className="flex flex-col">
                                { product.compositionContents?.map((composition) => (
                                    <li className="flex gap-1 "> 
                                        <div><ArrowLongRightIcon className="w-[20px] h-[20px]"/></div> 
                                        { composition.item }
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="whatwedo-box overflow-hidden h-[40vh] cursor-pointer order-1">
                            <img className="rounded-t-lg object-center object-contain w-full h-full duration-500" src={img} alt={ product.name } />
                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
    );
     
    return (
        <>
            <PagesBanner textSize="text-1xl" img={element.imgBanner} title={`Products / ${element.libelle}`} underTitle={ categoryDetail?.libelle } />
            <section className="w-full">
                <div className="bg-slate-100">
                <div className="container">
                    <div className="py-5">
                    <div className="gap-3 w-full bg-wite rounded-lg p-3">
                        <ProductContent/>

                        <div className="block lg:flex gap-3">
                            <div className="hidden mt-3 lg:flex w-full lg:w-[25%] flex-col bg-slate-50 max-h-[80vh] rounded-lg p-3  ">
                                <h4 className="text-xl font-semibold"><Link to="/products" >All <span className="lowercase">{ element.libelle } Products </span></Link></h4>
                                <div className="flex w-full flex-row lg:flex-col overflow-x-hidden min-h-[50vh] max-h-[80vh] overflow-y-auto flex-nowrap items-start justify-start ">
                                    {
                                        element?.categories?.map((category, idx) => (
                                            // category.category ? 
                                            //     <div id={idx} className="w-full">
                                            //         <div className="text-left ml-1 duration-300 ">
                                            //             <p className="font-semibold text-sm capitalize">{ category?.libelle }</p>
                                            //             {
                                            //                 category?.category?.map((categ,idx) => (
                                            //                     <>
                                            //                         <Link id={idx} to={"/products/"+productId+"/"+categ?.id} className=" py-1 flex w-full hover:bg-slate-100 items-center px-[5px] rounded justify-start ">
                                            //                             <ArrowRightCircleIcon className="w-[15px] h-[15px] mr-1"/>
                                            //                             <span className="rounded text-nowrap p-auto font-normal text-sm cursor-pointer">{ categ?.libelle }</span>
                                            //                         </Link>
                                            //                     </>
                                            //                 ))
                                            //             }
                                            //         </div>
                                            //     </div>
                                            // : 
                                            <div id={idx} className="w-full">
                                                <div className="text-left  duration-300 ">
                                                    <Link to={"/products/"+productId+"/"+category.id} className=" py-1 rounded hover:bg-slate-200 flex w-full items-center px-1 justify-start ">
                                                        <ArrowRightCircleIcon className="w-[15px] h-[15px] mr-1"/>
                                                        <span className="rounded text-nowrap p-auto font-normal text-sm cursor-pointer truncate">{ category.libelle }</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div> 
                            <div className=" pb-6 mt-3 flex-1 rounded-lg py-3 px-5 bg-white">
                                <h4 className="text-1xl "> 
                                    <span className="capitalize">
                                        { categoryDetail?.libelle }
                                    </span> 
                                </h4>
                                <div className=" max-h-[70vh] overflow-y-auto overflow-x-hidden">
                                    <div className={`grid mt-5 ${element.isCard ? "grid-cols-3 md:grid-cols-5" : "grid-cols-3 md:grid-cols-2" } gap-1 px-2 place-content-center `}>
                                        {
                                            categoryDetail?.contents?.map((products, idx) => (
                                                !element.isCard ?
                                                    <div id={idx} className="bg-green-50 hover:bg-green-100  max-md:flex-col duration-500 flex justify-between items-center whatwedo-box  overflow-hidden pointer p-[0px] border border-gray-200 rounded-lg shadow">
                                                        <div className="mt-3 px-1 max-md:w-full w-[70%] pb-1 max-md:order-2">
                                                            <p className="text-wrap"> { products.name } </p>
                                                            {
                                                                products.modelImg && 
                                                                <div className="overflow-hidden w-[40px] h-[40px]  rounded-full border border-solid border-slate-300 p-[2px] block flex-1">
                                                                    <img className="object-contain  w-full h-full duration-500" src={products.modelImg} alt={products.name} />
                                                                </div> 
                                                            }
                                                            <p className="text-sm font-semibold"> { products.model !== "" && "Model : " + products.model} </p>
                                                        </div>
                                                        <div className="overflow-hidden max-md:order-1 max-md:w-[100px] max-md:h-[100px] p-1 block flex-1 md:h-[5rem]">
                                                            <img className="rounded-t-lg scale-100 object-center object-contain w-full h-full duration-500" src={products.img} alt={products.name} />
                                                        </div>
                                                    </div>
                                                    :
                                                    <Popover id={idx} trigger="hover" className="w-[90vw] outline-none lg:w-[70vw] shadow-md bg-white z-[999] rounded-lg" content={contents(products, products.img)} placement="bottom">
                                                        <div className=" bg-green-50 hover:bg-green-100 duration-500 md:max-h-[140px] flex justify-between items-center flex-col whatwedo-box  overflow-hidden pointer p-[0px] border border-gray-200 rounded-lg shadow">
                                                            <div className="overflow-hidde w-full p-1 block flex-1">
                                                                <img className="rounded-t-lg object-center object-cover w-full h-full duration-500" src={products.img} alt={products.name} />
                                                            </div>
                                                        </div>
                                                    </Popover>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    </div>
                </div>
                </div>
            </section>
        </>
    )
}

export default ProductDetail