import React from 'react'
import { EnvelopeIcon, MapPinIcon, PhoneIcon } from '@heroicons/react/24/outline';

const ContactElement = () => {
    return (
        <>
            <section className="w-full">
                <div className="bg-slate-50">
                    <div className="container">
                        <div className="flex py-[50px] md:justify-center lg:justify-between  items-center flex-col md:flex-row flex-wrap ">
                            <div className="w-full md:w-[47%] whatwedo-box lg:w-[31%] my-[10px] mr-[20px] border-solid border-l-4 border-l-green-900 bg-white overflow-hidden p-[0px] border border-green-100 rounded-lg shadow">
                                <div className="py-4 px-3 flex items-center ">
                                    <div className="mr-2">
                                        <MapPinIcon className="w-[40px] h-[40px]" />
                                    </div>
                                    <div>
                                        <h4 className="text-1xl text-left py-1 font-medium">Adress info</h4>
                                        <p className="font-normal text-justify">
                                            Shoal House Plot 76, Bombo Rd
                                            <br/>
                                            1st Floor Room No.5
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-[47%] whatwedo-box lg:w-[31%] my-[10px] mr-[20px] border-solid border-l-4 border-l-green-900 bg-white overflow-hidden p-[0px] border border-green-100 rounded-lg shadow">
                                <div className="py-4 px-3 flex items-center ">
                                    <div className="mr-2">
                                        <PhoneIcon className="w-[40px] h-[40px]" />
                                    </div>
                                    <div>
                                        <h4 className="text-1xl text-left py-1 font-medium">Number Phone</h4>
                                        <p className="font-normal text-justify">
                                            (+256) 756 332 715
                                            <br/>
                                            (+256) 783 115 424
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full md:w-[47%] whatwedo-box lg:w-[31%] my-[10px] mr-[20px] border-solid border-l-4 border-l-green-900 bg-white overflow-hidden p-[0px] border border-green-100 rounded-lg shadow">
                                <div className="py-4 px-3 flex items-center ">
                                    <div className="mr-2">
                                        <EnvelopeIcon className="w-[40px] h-[40px]" />
                                    </div>
                                    <div>
                                        <h4 className="text-1xl text-left py-1 font-medium">Email</h4>
                                        <p className="font-normal text-justify">
                                            info@pharmasourcing.co.ug
                                            <br/>
                                            pharma.sourcing@yahoo.com
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactElement