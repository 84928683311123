import React from 'react'

import { BannerContent, HomeAbout } from '../components/Elements/home';

const Home = () => {
  return (
    <>
        <BannerContent/>
        <HomeAbout/>
    </>
  )
}

export default Home