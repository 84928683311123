import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import NewsContent from './NewsContent';

const NewsAndUpdateElement = () => {
    return (
        <>
            <section className="w-full">
                <div className="bg-slate-100">
                    <div className="container">
                        <div className="py-5">
                            <div className="block md:flex md:justify-center md:flex-wrap">
                                
                                {
                                    NewsContent.newUpdates.map((element, idx) => (
                                        <div className="whatwedo-box relative pb-[5rem] w-full lg:w-[48%] my-[10px] mr-[20px] bg-white overflow-hidden p-[0px] border border-gray-200 rounded shadow">
                                            <div className="overflow-hidden relative block w-full h-[46vh]">
                                                <img className="rounded-t-lg object-cover w-full h-full  duration-500" src={element.img} alt="..." />
                                                <div className="w-full h-full bg-green-900 bg-opacity-30 absolute top-[0] left-[0]"></div>
                                            </div>
                                            <div className="p-2 ">
                                                <div>
                                                    <small>{ element.date }</small>
                                                    <h5 className="mb-2 text-xl md:text-1xl font-medium tracking-tight">
                                                        {element.title}
                                                    </h5>
                                                </div>
                                                <p className="font-normal text-justify">
                                                    { element.content }
                                                </p>

                                            </div>
                                            <div className="py-4 absolute bottom-2 left-2">
                                                <Link to={ element.id } className=" bg-green-700 flex items-center bottom-1 hover:bg-green-800 text-slate-100 hover:text-slate-100 text-md px-3 py-2 rounded">
                                                    Read more
                                                    <ArrowLongRightIcon className="w-[20px] ml-1 text-slate-100 h-[20px]" />
                                                </Link>
                                            </div>
                                        </div>
                                    ))
                                }
                                                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewsAndUpdateElement