

const data = {
    partener:[
        {
            id:1,
            logo: "/partener/1.jpg",
            alt: "Akriti",
        },
        {
            id:2,
            logo: "/partener/2.jpg",
            alt: "Appasamy"
        },
        {
            id:3,
            logo: "/partener/3.jpg",
            alt: "BrienHolden"
        },
        {
            id:4,
            logo: "/partener/4.jpg",
            alt: "The carter center"
        },
        {
            id:5,
            logo: "/partener/5.jpg",
            alt: "Dr. Agarwal's"
        },
        {
            id:6,
            logo: "/partener/6.jpg",
            alt: "Georgina"
        },
        {
            id:7,
            logo: "/partener/7.jpg",
            alt: "Mengo Hospital"
        },
        {
            id:8,
            logo: "/partener/8.jpg",
            alt: "Lubaga Hospital"
        },
        {
            id:9,
            logo: "/partener/9.jpg",
            alt: "Sightsavers"
        },
        
    ]
}

export default data;