import React from 'react'
import AboutImg1 from "../../../assets/img/about/3.jpg";
import AboutImg2 from "../../../assets/img/about/certificate.jpg";
import AboutImg3 from "../../../assets/img/about/policy.png";

const AboutElement = ({hidden = ""}) => {
    return (
        <>
           <section className="w-full">
                <div className="bg-slate-100">
                    <div className="container">
                        <div className="py-[50px]">
                            <div className={`flex ${hidden} flex-wrap justify-between items-center py-[20px]`}>
                                <div className="w-full md:w-[45%]">
                                    <h2 className="text-[40px] font-medium">Pharma Sourcing</h2>
                                    <p className=" text-justify">
                                        It was incorporated in 2015 and is also registered with the National Drug Authority.
                                        Pharma Sourcing povides ophtamolomic devices, instruments for examination, as well as optometric items for refraction.
                                        We also provide sourcing services for rare to find pharmaceutical items through our local and international partnerships.
                                    </p>
                                </div>
                                <div className="w-full md:w-[50%] rounded h-[46vh]">
                                    <div className="w-full h-full overflow-hidden rounded">
                                        <img src={AboutImg1} alt="..." className="h-full pointer duration-500 hover:scale-[1.2] hover:rotate-[2deg] w-full object-cover"/>
                                    </div>
                                </div>    
                            </div>
                            <div className="flex flex-wrap max-md:flex-col-reverse justify-between items-center py-[20px]">
                                <div className="w-full md:w-[50%] rounded h-[46vh] pointer bg-white">
                                    <a href="/documents/ISO-Certificate.pdf" rel="nooper noreferrer" target="_blank" className="w-full h-full overflow-hidden flex justify-center rounded">
                                        <img src={AboutImg2} alt="..." className="rounded scale-[1.09] hover:scale-[1.2] duration-500"/>
                                    </a>
                                </div> 
                                <div className="w-full md:w-[45%]">
                                    <h2 className="text-[40px] font-medium">Quality management systems</h2>
                                    <p className=" text-justify">
                                        it is a formalized set that documents the processes, 
                                        procedures and responsibilities for achieving quality policies and objectives.
                                    </p>
                                </div>
                            </div>
                            <div className="flex flex-wrap justify-between items-center py-[20px]">
                                <div className="w-full md:w-[45%]">
                                    <h2 className="text-[40px] font-medium">Read policy</h2>
                                    <p className=" text-justify">
                                        The quality policy is a general statement of an organization's commitment to quality. <br/>
                                        It expresses the organization's commitment to respecting customer requirements, legal requirements and standard requirements.
                                    </p>
                                </div>
                                <div className="w-full md:w-[50%] rounded h-[46vh] pointer bg-white">
                                    <a href="/documents/QUALITY-POLICY.pdf" rel="nooper noreferrer" target="_blank" className="w-full h-full overflow-hidden flex justify-center rounded">
                                        <img src={AboutImg3} alt="..." className="rounded scale-[1.09] hover:scale-[1.2] duration-500"/>
                                    </a>
                                </div> 
                            </div>
                        </div>
                    </div>

                </div>
            </section> 
        </>
    )
}

export default AboutElement