import React from 'react'
import AboutBanner from "../assets/img/about/7.jpg";
import { PagesBanner } from '../components/partials';
import { Passion } from '../components/Elements/home';
import AboutElement from '../components/Elements/About/AboutElement';

const About = () => {
    return (
        <>
            <PagesBanner img={AboutBanner} title="About us" />
            <AboutElement/>
            <Passion/>

        </> 
    )
}

export default About;