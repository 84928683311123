import React from 'react'
import { PagesBanner } from '../components/partials'
import ProductContent from '../components/Elements/Products/ProductContent';
import ProductItems from '../components/Elements/Products/ProductItems';
import AddImage1 from "../assets/img/home/passion/machine.jpg";
import AddImage2 from "../assets/img/home/homeabout/7.jpg";
import AddImage3 from "../assets/img/home/homeabout/33.jpg";
import AddImage4 from "../assets/img/home/homeabout/44.jpg";

const Products = () => {
  return (
    <>
      <PagesBanner img={ProductItems.img} title="Products" />
      <section className="w-full">
        <div className="bg-slate-100">
          <div className="container">
            <div className="py-5">
              <div className=" gap-3 w-full rounded-lg p-3">
                <ProductContent/>
                
                <div className="flex mt-3 bg-white  items-center flex-col md:flex-row relative justify-between pb-6 max-lg:mt-5 flex-1 rounded-lg py-3 px-5">
                  <div className="w-[80%] order-2 md:order-1 md:mr-[50px]">
                    <h2 className="text-[40px] font-extrabold">Improve your vision</h2>
                    <p className="text-justify">
                      Discover our range of high-quality products to care for your eyes.
                      whether you're looking for glasses, contact lenses, or eye care solutions,
                      we have everything you need.
                      Our experts are here to advise you and help you find the perfect product tailored to your vision.
                    </p>
                  </div>
                  <div className="w-[80%] relative order-1 md:order-2 top-[0] md:w-[50%] md:absolute md:top-[20px] right-[0]">
                    <div className="w-full">
                      <div className="flex items-center justify-center">
                        <div className="flex justify-center w-[48%] m-[0_6%_10px_6%]">
                          <img src={AddImage1} alt="office" className="w-full h-auto rounded-[1rem] shadow-[0_2px_15px]" />
                        </div>
                        <div className=" flex justify-center w-[30%] m-[0_5%_10px_5%]">
                          <img src={AddImage2} alt="office" className="w-full h-auto rounded-[1rem] shadow-[0_2px_15px]" />
                        </div>
                      </div>
                      <div className="flex items-center justify-center">
                        <div className=" flex justify-center w-[20%] ml-[40%]">
                          <img src={AddImage3} alt="office" className="w-full h-auto rounded-[1rem] shadow-[0_2px_15px]" />
                        </div>
                        <div className=" flex justify-center w-[30%] m-[0_5%_auto]">
                          <img src={AddImage4} alt="office" className="w-full h-auto rounded-[1rem] shadow-[0_2px_15px]" />
                        </div>
                      </div>
                    </div>
                  </div>
              </div>

                <div className="relative hidden pb-6 max-lg:mt-5 flex-1 rounded-lg py-3 px-5 bg-white">
                  <h4 className="text-1xl lg:text-2xl"> Products</h4>
                  <p className="text-justify">
                    Discover our range of high-quality products to care for your eyes.
                    whether you're looking for glasses, contact lenses, or eye care solutions,
                    we have everything you need.
                    Our experts are here to advise you and help you find the perfect product tailored to your vision.
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Products