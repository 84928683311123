import React, { useEffect, useState } from 'react'
import Gallery from './Gallery'

const GalleryContent = ({ numElement }) => {
    const [identElement, setidentElement] = useState([]);

    useEffect(() => {
        switch (numElement){
            case 1:
                setidentElement(Gallery.add1)
                break;
            case 2:
                setidentElement(Gallery.add2)
                break;
            case 3:
                setidentElement(Gallery.add3)
                break;
            case 4:
                setidentElement(Gallery.add4)
                break;
            default:
                setidentElement(Gallery.add5)
                break;
        }
    }, [numElement])
    
    return (
        <>
            <div className="grid  grid-cols-2 md:grid-cols-4 gap-4 lg:gap-1">
                {
                    identElement.map((item, idx) => (
                        <div id={idx} className="max-h-[210px]  rounded-lg relative whatwedo-box overflow-hidden">
                            <div className="w-full duration-500 h-full bg-gray-900 z-[1] bg-opacity-20 absolute top-[0] left-[0]"></div>
                            <img className="h-full w-full object-cover duration-500" src={item.img} alt="..." />
                        </div>
                    ))
                }
            </div>


        </>
    )
}

export default GalleryContent