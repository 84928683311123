import React from 'react'
import ImgBanner from "../assets/img/home/banner/3.png";
import { PagesBanner } from '../components/partials'

const Media = () => {
  return (
    <>
        <PagesBanner img={ImgBanner} title="Media" />
    </>
  )
}

export default Media