import React, { useEffect, useState } from "react";
// Components
import Sidebar from "./Sidebar";
import { Backdrop } from "../partials";
// Assets
import BurgerIcon from "../../assets/svg/BurgerIcon";
import Logo from "../../assets/img/logo.png"
import { Link } from "react-router-dom";

const TopNavbar = () => {
  const [y, setY] = useState(window.scrollY);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => setY(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () => setY(window.scrollY));
    };
  }, [y]);

  return (
    <>
      <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}
      <div className="flex overflow-hidden w-full fixed top-[0] left-[0] z-[888] animate" style={y > 100 ? { height: "90px", boxShadow:"0 1px 14px #000", backgroundColor: "white", color: "#166534"  } : { height: "80px" }}>
        <div className="container relative h-full flex  justify-between">
          <Link className="pointer flexNullCenter" to="/">
            <h1 className="font20">
              <img className="rounded border-[0] w-[120px]" src={Logo} alt="add" />
            </h1>
          </Link>
         <div className="flex justify-between">
           <button style={{outline: "none"}} className="pointer bg-transparent h-full px-[0] py-[15px] border-[0px] block md:hidden" onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </button>
          <ul className="hidden md:flex items-center ">
            <li className="semiBold pointer">
              <Link to="/" activeClass="active" className={` px-[15px] py-[10px] ${y > 100 ? "text-primary-txt" : "text-slate-100"}`}>
                Home
              </Link>
            </li>
            <li className="semiBold pointer">
              <Link to="/about" activeClass="active" className={`px-[15px] py-[10px] ${y > 100 ? "text-primary-txt" : "text-slate-100"}`} >
                About
              </Link>
            </li>
            <li className="semiBold pointer">
              <Link to="/products" activeClass="active" className={`px-[15px] py-[10px] ${y > 100 ? "text-primary-txt" : "text-slate-100"}`}>
                Products
              </Link>
            </li>
            
            <li className="semiBold pointer">
              <Link to="/newsandupdate" activeClass="active" className={`px-[15px] py-[10px] ${y > 100 ? "text-primary-txt" : "text-slate-100"}`}>
                New & Updates
              </Link>
            </li>
            <li className="semiBold hidden pointer">
              <Link to="/media" activeClass="active" className={`px-[15px] py-[10px] ${y > 100 ? "text-primary-txt" : "text-slate-100"}`}>
                Media
              </Link>
            </li>

            <li className="semiBold pointer">
              <Link to="/contact" activeClass="active" className={`px-[15px] py-[10px] ${y > 100 ? "text-primary-txt" : "text-slate-100"}`}>
                Contact
              </Link>
            </li>

            {/* <li className="semiBold pointer">
              <Link to="/contact" activeClass="active" className={`px-[15px] py-[10px] text-primary-txt  pointer text-center rounded-[30px] hover:text-slate-100 bg-slate-100  duration-500 ${y > 100 ? "hover:bg-primary-bg" : "hover:bg-primary-tx"}`}>
                Contact
              </Link>
            </li> */}
          </ul>
          
         </div>
        </div>
      </div>
    </>
  );
}

export default TopNavbar;