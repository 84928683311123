import React from 'react'

import AddImage1 from "../../../assets/img/home/passion/machine.jpg";
import AddImage2 from "../../../assets/img/home/homeabout/7.jpg";
import AddImage3 from "../../../assets/img/home/homeabout/33.jpg";
import AddImage4 from "../../../assets/img/home/homeabout/44.jpg";
import { Passion, Whatwedo } from './';
import AboutElement from '../About/AboutElement';

const HomeAbout = () => {
    return (
        <>
            <section className="w-full bg-slate-100">
                <div className="bg-slate-100">
                    <div className="container">
                        <div className="flex  py-[20px] items-center flex-col md:flex-row relative justify-between">
                            <div className="w-[80%] order-2 md:order-1 md:mr-[50px]">
                                <h2 className="text-[40px] font-extrabold">Your ophtamologist partener</h2>
                                <p className="text-justify">
                                    Pharma Sourcing povides ophtamolomic devices, instruments for examination, as well as optometric items for refraction.
                                    <br/>We also provide sourcing services for rare to find pharmaceutical items through our local and international partnerships.
                                    <br/>We deliver a wide range of products ranging from ophthalmic devices, instruments for examination, diagnosis, treatment as well as optometric items for refraction.
                                </p>
                            </div>
                            <div className="w-[80%] relative order-1 md:order-2 top-[-15px] md:w-[50%] md:absolute right-[0]">
                                <div className="w-full">
                                    <div className="flex items-center justify-center">
                                        <div className="flex justify-center w-[48%] m-[0_6%_10px_6%]">
                                        <img src={AddImage1} alt="office" className="w-full h-auto rounded-[1rem] shadow-[0_2px_15px]" />
                                        </div>
                                        <div className=" flex justify-center w-[30%] m-[0_5%_10px_5%]">
                                        <img src={AddImage2} alt="office" className="w-full h-auto rounded-[1rem] shadow-[0_2px_15px]" />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-center">
                                        <div className=" flex justify-center w-[20%] ml-[40%]">
                                        <img src={AddImage3} alt="office" className="w-full h-auto rounded-[1rem] shadow-[0_2px_15px]" />
                                        </div>
                                        <div className=" flex justify-center w-[30%] m-[0_5%_auto]">
                                        <img src={AddImage4} alt="office" className="w-full h-auto rounded-[1rem] shadow-[0_2px_15px]" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Passion/>

                {/* <Whatwedo/> */}
                <AboutElement hidden="hidden"/>

            </section>
        </>
    )
}

export default HomeAbout