const Gallery = {
    add1:[
        { img: "/img/add1/1.jpg"},
        { img: "/img/add1/2.jpg"},
        { img: "/img/add1/3.jpg"},
        { img: "/img/add1/4.jpg"},
        { img: "/img/add1/5.jpg"},
        { img: "/img/add1/6.jpg"},
        { img: "/img/add1/7.jpg"},
        { img: "/img/add1/8.jpg"},
        { img: "/img/add1/9.jpg"},
    ],
    add2:[
        { img: "/img/add2/1.png"},
        { img: "/img/add2/2.png"},
        { img: "/img/add2/3.png"},
        { img: "/img/add2/4.png"},
    ],
    add3:[
        { img: "/img/add3/1.jpeg"},
        { img: "/img/add3/2.jpeg"},
        { img: "/img/add3/3.jpeg"},
        { img: "/img/add3/4.jpeg"},
    ],
    add4:[
        { img: "/img/add4/1.jpg"},
        { img: "/img/add4/2.jpg"},
        { img: "/img/add4/3.jpg"},
        { img: "/img/add4/4.jpg"},
        { img: "/img/add4/5.jpg"},
        { img: "/img/add4/6.jpg"},
        { img: "/img/add4/7.jpg"},
        { img: "/img/add4/8.jpg"},
        { img: "/img/add4/9.jpg"},
        { img: "/img/add4/10.jpeg"},
        { img: "/img/add4/11.jpg"},
        { img: "/img/add4/12.jpeg"},
        { img: "/img/add4/13.jpeg"},
        { img: "/img/add4/14.jpeg"},
        { img: "/img/add4/15.jpeg"},
        { img: "/img/add4/16.jpeg"},
    ],
    add5:[
        { img: "/img/add5/1.jpg"},
    ],
}

export default Gallery;