
import { Link } from "react-router-dom";
import ProductItems from "./ProductItems";
import { Popover } from "flowbite-react";


const ProductContent = () => {
    const contents = (items, productId) => (
        <div className="w-full text-sm text-gray-500 ">
            <div className="grid grid-cols-5">
              <div className="col-span-5 p-3">
                <div className="space-y-2 max-h-[260px] overflow-x-hidden overflow-y-auto">
                    <div className="grid grid-cols-3 md:grid-cols-4 gap-1 w-full">
                        {
                            items?.map((categorie,idx) => (
                                <Link id={idx} to={"/products/"+productId+"/"+categorie.id} className="flex outline-none hover:bg-slate-100 duration-500 rounded py-[3px] w-full items-center px-1 justify-start ">
                                    <span className="rounded text-wrap p-auto font-normal text-sm cursor-pointer">{ categorie.libelle } <br/> { categorie.br }</span>
                                </Link>
                            ))
                            
                        }
                    </div>
                </div>
              </div>
            </div>
        </div>
    );
    return (
        <>
            <div className=" w-full flex-row bg-slate-50  rounded-lg p-3  ">
                <div className="flex items-center justify-between">
                    <h4 className="text-xl font-semibold"><Link to="/products" >All Products</Link></h4>
                </div>
                <div className="flex w-full flex-row lg:flexcol max-sm:overflow-x-scroll overflow-y-hidden flex-nowrap items-start justify-start ">
                    {
                        ProductItems.products.map((products, idx) => (
                            <>
                                <div className="w-full border-2 border-solid border-transparent hover:border-b-gray-300">
                                    <div className="text-left  duration-300 ">
                                        <Popover id={idx} trigger="hover" className=" outline-none lg:w-[70vw] shadow-md bg-white z-[999] rounded-lg" content={contents(products.categories, products?.id)} placement="bottom">
                                            <button className=" py-1 flex flex-col w-full items-center px-1 justify-start ">
                                                <products.icon className="w-[20px] h-[20px] mr-1"/><span className="rounded text-nowrap p-auto font-normal text-sm cursor-pointer">{ products.libelle }</span>
                                            </button>
                                        </Popover>
                                    </div>
                                </div>
                            </>
                        ))
                    }
                </div>
            </div> 
                        
        </>
    );
}

export default ProductContent
