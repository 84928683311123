import React from "react";
import Logo from "../../assets/img/logo.png"
import AboutImg2 from "../../assets/img/about/certificate.jpg";
import FooterImg from "../../assets/2.png"
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="relative ">
            
                <div className="bg-[rgb(2,18,9)] z-[-1] absolute w-full h-full opacity-95 left-[0] top-[0]"></div>
                    <img className="absolute z-[-2] object-cover w-full h-full duration-500" src={FooterImg} alt="" />
                    <div className="mx-auto w-full z-[1] max-w-screen-xl p-4 py-6 lg:py-8">
                        <div className="md:flex md:justify-between">
                            <div className="mb-6 flex-1 md:mb-0">
                                <Link className="flex items-center">
                                    <img className="rounded border-[0] w-[120px]" src={Logo} alt="add" />
                                </Link>
                                <p className="text-slate-100 mt-4 max-w-sm font-normal">Pharma Sourcing povides ophtamolomic devices, instruments for examination, as well as optometric items for refraction.</p>
                                <div className="sm:flex sm:items-center sm:justify-start">
                                    <div className="flex mt-4 p-[0px] sm:justify-center sm:mt-0">
                                        <a href="#" className="text-slate-100 hover:text-primary-tx">
                                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                                                    <path fill-rule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clip-rule="evenodd"/>
                                                </svg>
                                            <span className="sr-only">Facebook page</span>
                                        </a>
                                        <a href="#" className="text-slate-100 hover:text-primary-tx ms-5">
                                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 21 16">
                                                    <path d="M16.942 1.556a16.3 16.3 0 0 0-4.126-1.3 12.04 12.04 0 0 0-.529 1.1 15.175 15.175 0 0 0-4.573 0 11.585 11.585 0 0 0-.535-1.1 16.274 16.274 0 0 0-4.129 1.3A17.392 17.392 0 0 0 .182 13.218a15.785 15.785 0 0 0 4.963 2.521c.41-.564.773-1.16 1.084-1.785a10.63 10.63 0 0 1-1.706-.83c.143-.106.283-.217.418-.33a11.664 11.664 0 0 0 10.118 0c.137.113.277.224.418.33-.544.328-1.116.606-1.71.832a12.52 12.52 0 0 0 1.084 1.785 16.46 16.46 0 0 0 5.064-2.595 17.286 17.286 0 0 0-2.973-11.59ZM6.678 10.813a1.941 1.941 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.919 1.919 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Zm6.644 0a1.94 1.94 0 0 1-1.8-2.045 1.93 1.93 0 0 1 1.8-2.047 1.918 1.918 0 0 1 1.8 2.047 1.93 1.93 0 0 1-1.8 2.045Z"/>
                                                </svg>
                                            <span className="sr-only">Discord community</span>
                                        </a>
                                        <a href="#" className="text-slate-100 hover:text-primary-tx ms-5">
                                            <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 17">
                                                <path fill-rule="evenodd" d="M20 1.892a8.178 8.178 0 0 1-2.355.635 4.074 4.074 0 0 0 1.8-2.235 8.344 8.344 0 0 1-2.605.98A4.13 4.13 0 0 0 13.85 0a4.068 4.068 0 0 0-4.1 4.038 4 4 0 0 0 .105.919A11.705 11.705 0 0 1 1.4.734a4.006 4.006 0 0 0 1.268 5.392 4.165 4.165 0 0 1-1.859-.5v.05A4.057 4.057 0 0 0 4.1 9.635a4.19 4.19 0 0 1-1.856.07 4.108 4.108 0 0 0 3.831 2.807A8.36 8.36 0 0 1 0 14.184 11.732 11.732 0 0 0 6.291 16 11.502 11.502 0 0 0 17.964 4.5c0-.177 0-.35-.012-.523A8.143 8.143 0 0 0 20 1.892Z" clip-rule="evenodd"/>
                                            </svg>
                                            <span className="sr-only">Twitter page</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="hidden  md:flex sm:gap-6">
                                <div>
                                    <h2 className="mb-4 text-xl font-semibold text-slate-200">Pages</h2>
                                    <ul className="text-slate-100 font-medium">
                                        <li className="mb-2">
                                            <Link to="/" className="text-slate-100 font-normal hover:text-primary-tx">Home</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="/about" className="text-slate-100 font-normal hover:text-primary-tx">About</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="/products" className="text-slate-100 font-normal hover:text-primary-tx">Products</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="/newsandupdate" className="text-slate-100 font-normal hover:text-primary-tx">News and update</Link>
                                        </li>
                                        <li className="mb-2">
                                            <Link to="contact" className="text-slate-100 font-normal hover:text-primary-tx">Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className=" ">
                                    <div className="w-full rounded h-[46vh] pointer bg-white">
                                        <a href="/documents/ISO-Certificate.pdf" rel="nooper noreferrer" target="_blank" className="w-full h-full overflow-hidden flex justify-center rounded">
                                            <img src={AboutImg2} alt="..." className="rounded scale-[1.09] hover:scale-[1.2] duration-500"/>
                                        </a>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        <div>
                            <ul className="text-slate-100 font-medium">
                                <li className="mb-2">
                                <span className="text-sm text-slate-100 sm:text-center">© 2024 <Link to="/" className="hover:text-slate-100 font-normal text-primary-tx">PharmaSourcing</Link> <br/>All Rights Reserved.</span>
                                </li>
                                <li className="mb-2">
                                </li>
                            </ul>
                        </div>
                </div>
            </footer>
  
        </>
    )
}
export default Footer;