import Img1 from "../../../assets/img/news/1.jpg";
import Img2 from "../../../assets/img/news/2.jpeg";
import Img3 from "../../../assets/img/news/3.jpeg";
import Img4 from "../../../assets/img/news/4.jpg";
import Img5 from "../../../assets/img/news/5.jpg";

const NewsContent = {
    newUpdates: [
        {
            num: 1,
            id: "44417e5c-00d5-4f8c-bd4e-06b8bc3453e5",
            img: Img1,
            date: "MARCH 2, 2018",
            title: "COORDINATED APPROACH TO COMMUNITY HEALTH (CATCH)",
            content: "Pharma Sourcing Limited assisted in Surgical Supplies for the CATCH program run by Sight Savers, one of the largest organization in the world leading in programs that are designed to improve patients way of life through fighting blindness and other neglected tropical diseases in Uganda since 1954 See more https://www.sightsavers.org/",
        },
        {
            num: 2,
            id: "1dd75efb-3f45-42d6-b85b-7855ff596079",
            img: Img2,
            date: "JUNE 28, 2019",
            title: "PHARMA SOURCING PURCHASES SPECTRUM PHARMACY",
            content: "At the close of June 2019, Pharma Sourcing purchased and took over Spectrum Pharmacy. This is a huge step towards growth and expansion or its clientale base. The process of Renovation, rebranding and changes in documentaion is on- going. Pharma Sourcing hopes to reach a wider market and serve its clients better",
        },
        {
            num: 3,
            id: "3eca8c87-d663-4750-903b-52c065a69b68",
            img: Img3,
            date: "AUGUST 8, 2019",
            title: "BRIEN HOLDEN EYE CAMPS SUPPORTED BY PHARMA SOURCING LTD SUPPLIES",
            content: "A Pictorial of Brien Holden Eye Camps Supported By Pharma Sourcing Ltd Supplies",
        },
        {
            num: 4,
            id: "a6193733-1fb4-4ba6-8423-d4b02c8048fa",
            img: Img4,
            date: "AUGUST 21, 2019",
            title: "EQUIPMENT WORTH $100.000 DONATED TO EYE CARE CENTERS IN UGANDA",
            content: "PHARMA SOURCING LIMITED handing over to the Minister of Primary Health Care; Equipment worth $100.000 donated to eye care centers in Uganda in support of Vision 2020 project. Equipment was donated by BREIN HOLDEN VISUAL INSTITUTE and Standard Chartered Bank Uganda Limited.",
        },
        {
            num: 5,
            id: "bcf7dc0f-8dca-48b8-bb56-ba422fb453f7",
            img: Img5,
            date: "OCTOBER 22, 2018",
            title: "LAUNCH OF RETINA PROJECT AT MBARARA UNIVERSITY REFFERAL AND EYE CENTER",
            content: "Pharma Sourcing Limited Supplied ALCON VITRECTOMY SYSTEM and surgical cosumables at Mbarara university refferal hospital and eye centre in their bid to train retina specialist surgeons, treat retina cases and also create public awareness on retina conditions.",
        }
    ]
}

export default NewsContent;