import React, { useEffect, useState } from 'react'
import ImgBanner from "../assets/img/news/5.jpg";
import { PagesBanner } from '../components/partials'
import { Link, useParams } from 'react-router-dom'
import NewsContent from '../components/Elements/NewsAndUpdate/NewsContent';
import GalleryContent from '../components/Elements/NewsAndUpdate/GalleryContent';

const NewsAndUpdateDetail = () => {
    const { id } = useParams();
    const [element, setElement] = useState([]);

    useEffect(() => {
        const element = NewsContent.newUpdates.filter(element => (element.id === id));
        setElement(element[0]);
    }, [id])
    
    return (
        <>
            <PagesBanner img={ImgBanner} title="Detail" />
            <section className="w-full">
                <div className="bg-slate-100">
                    <div className="container">
                        <div className="py-5">
                            <div className="block md:flex md:flex-wrap gap-1">
                                <div className="whatwedo-box relative w-full md:w-[70%]">
                                    <div className="w-full h-[425px] overflow-hidden rounded-t-lg">
                                        <img src={element.img} className="w-full h-full object-cover duration-500" alt="..." />
                                    </div>
                                    <div className="p-2 bg-white rounded-b-lg">
                                        <div>
                                            <small>{ element.date }</small>
                                            <h5 className="mb-2 text-xl md:text-1xl font-medium tracking-tight">
                                                {element.title}
                                            </h5>
                                        </div>
                                        <p className="font-normal text-justify">
                                            { element.content }
                                        </p>

                                    </div>
                                </div>
                                <div className="w-full hidden bg-white rounded-lg max-h-[50vh] md:block md:w-[28%]">
                                    <div className="px-3 py-3">
                                        <h5 className="mb-2 text-xl md:text-1xl font-medium tracking-tight">
                                            Other news 
                                        </h5>
                                        <div className="flex flex-col">
                                            {
                                                NewsContent.newUpdates.map((item, idx) => (
                                                    <Link to={"/newsandupdate/"+item.id} id={idx} className=" flex py-1 rounded text-wrap pl-2 hover:bg-green-300 duration-500 border border-transparent border-solid border-b-green-200">
                                                        <span className="block truncate text-sm font-semibold lowercase"> { item.title} </span>
                                                    </Link>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="w-full">
                <div className="bg-green-100">
                    <div className="container">
                        <div className="py-5">
                            <GalleryContent numElement={ element.num }/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NewsAndUpdateDetail;