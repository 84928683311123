import React from 'react'

const ContactForm = ({img, FormImg}) => {
    return (
        <>
            <section className="w-full">
                <div className="bg-slate-50">
                    <div 
                        style={{backgroundImage:`url(${img})`}}
                        className=" bg-fixed bg-cover bg-norepeat bg-center relative overflow-hidden min-h-[70vh]  ">
                        <div className="absolute h-full bg-opacity-60 w-full bg-green-950"></div>
                        <div className="container">
                            <div className="py-[50px]">
                                <div className="flex">
                                    <div className="w-full lg:w-[70%] z-[1] px-5 lg:px-[80px] py-4 bg-green-200 bg-opacity-80 rounded-lg lg:rounded-[0] lg:rounded-l-lg">
                                        <form className="mx-auto">
                                            <div className="pt-[5px] pb-[20px]">
                                                <h2 className="text-[40px] font-medium">Message us</h2>
                                            </div>
                                            <div className="relative mb-4">
                                                <input type="text" id="name" className="block p-3 pt- w-full text-sm text-gray-900 rounded border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " />
                                                <label for="name" className="absolute text-sm  text-gray-500  duration-300 bg-white transform -translate-y-4 scale-75 top-3 z-10 origin-[0] bg-transparent  px-2 peer-focus:px-2 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Name</label>
                                            </div>
                                            
                                            <div className="lg:flex justify-between">
                                                <div className="mb-4 relative w-full md:w-[48%]">
                                                    <input type="text" id="email" className="block p-3 pt- w-full text-sm text-gray-900 rounded border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " />
                                                    <label for="email" className="absolute text-sm  text-gray-500  duration-300 bg-white transform -translate-y-4 scale-75 top-3 z-10 origin-[0] bg-transparent  px-2 peer-focus:px-2 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Email</label>
                                                </div>
                                                <div className="mb-4 relative w-full md:w-[48%]">
                                                    <input type="text" id="phone" className="block p-3 pt- w-full text-sm text-gray-900 rounded border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " />
                                                    <label for="phone" className="absolute text-sm  text-gray-500  duration-300 bg-white transform -translate-y-4 scale-75 top-3 z-10 origin-[0] bg-transparent  px-2 peer-focus:px-2 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Phone Number</label>
                                                </div>
                                            </div>
                                            <div className="relative mb-4">
                                                <input type="text" id="subject" className="block p-3 pt- w-full text-sm text-gray-900 rounded border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " />
                                                <label for="subject" className="absolute text-sm  text-gray-500  duration-300 bg-white transform -translate-y-4 scale-75 top-3 z-10 origin-[0] bg-transparent  px-2 peer-focus:px-2 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Subject</label>
                                            </div>
                                            <div className="relative mb-4">
                                                <textarea rows="4" id="comment" className="block p-3 pt- w-full text-sm text-gray-900 rounded border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " ></textarea>
                                                <label for="comment" className="absolute text-sm  text-gray-500  duration-300 bg-white transform -translate-y-4 scale-75 top-3 z-10 origin-[0] bg-transparent  px-2 peer-focus:px-2 peer-focus:text-green-600 peer-focus:dark:text-green-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">Comment</label>
                                            </div>
                                            <button type="submit" className="text-white bg-green-900 hover:bg-green-950 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded text-sm md:text-md w-full sm:w-auto px-5 py-3 text-center">Submit</button>
                                        </form>
                                    </div>
                                    <div className="max-lg:hidden lg:w-[40%] max-h-[90vh]  z-[1] overflow-hidden bg-green-100 rounded-r-lg">
                                        <img src={FormImg} className="w-full h-full object-cover" alt="img contact form" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ContactForm