import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import { Home, Pageerror, Base, About, Products, NewAndUpdate, Media, Contact, NewsAndUpdateDetail, ProductDetail } from "../pages";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            {/* Web site router */}

            <Route
                path="/"
                element={<Base/>}
                errorElement={<Pageerror/>}
            >
                <Route index element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/products"element={<Products/>}/>
                <Route path="/products/:productId/:categoryId" element={<ProductDetail/>}/>
                <Route path="/newsandupdate" element={<NewAndUpdate/>}/>
                <Route path="/newsandupdate/:id" element={<NewsAndUpdateDetail/>}/>
                <Route path="/media" element={<Media/>}/>
                <Route path="/contact" element={<Contact/>}/>
            </Route>
        </>
    )
)
export default router;