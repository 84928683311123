import React from 'react'
import { Outlet } from 'react-router-dom'
import TopNavbar from "../components/Nav/TopNavbar";
import { Footer, Partener } from '../components/partials';

const Base = () => {
  return (
    <>
      <TopNavbar />
      <main className="p-[0px] m-[0px]  ">
        <Outlet />
      </main>
      <Partener/>
      <Footer/>
    </>
  )
}

export default Base