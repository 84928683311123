import React from 'react'

const PagesBanner = ({ img, title, underTitle = "", textSize="lg:text-4xl" }) => {
  return (
    <>
      <div 
        // style={{backgroundImage:`url(${img})`}}
        className=" h-[35vh] bg-fixed bg-cover bg-norepeat bg-bottom relative overflow-hidden md:h-[45vh] lg:h-[40vh]  ">
        <div className="flex relative h-full  items-center justify-center  ">
          <img src={img} alt="..." className="h-full fixed z-[-100]  w-full object-cover"/>
          <div className="absolute z-[100] h-full bg-opacity-60 w-full bg-green-950 "></div>
            <div className="z-[101] flex items-center justify-center ">
              <h1 className={` mx-[20px] max-lg:text-2xl text-center text-wrap ${textSize} tracking-[1.2px]`} >
                <span className="text-slate-100 font-extralight" >
                  {title} { (underTitle !== "") && "/" } <br/>
                  {underTitle}
                </span>
              </h1>
            </div>
        </div> 
      </div>
    </>
  )
}

export default PagesBanner