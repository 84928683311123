import React from 'react'
import ImgBanner from "../assets/img/contact/2.jpg";
import ContactBanner from "../assets/img/contact/1.jpg";
import FormImg from "../assets/img/contact/3.jpg";
import { PagesBanner } from '../components/partials'
import ContactElement from '../components/Elements/Contact/ContactElement';
import ContactForm from '../components/Elements/Contact/ContactForm';

const Contact = () => {
    return (
      <>
        <PagesBanner img={ImgBanner} title="Contact us" />
        <ContactElement/>
        <ContactForm img={ContactBanner} FormImg={FormImg}/>
      </>
    )
}

export default Contact